/**
 * create by hp
 * date 2020/2/24 13:20
 * @type {string[]}
 */

import Vue from 'vue'

export function checkApiServer (requestRes) {
  Vue.prototype.$closeLoadingS()
  if (requestRes.code !== '200') {
    Vue.prototype.$message.error(requestRes.message)
    throw new Error(requestRes.message)
  }
  return requestRes.data
}
/**
 * 检验api是否返回正常
 * @returns {*}
 */
export default () => {
  Vue.prototype.$checkApiServer = checkApiServer
}
