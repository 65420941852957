/**
 * create by hp
 * date 2020/2/26 17:33
 * @type {string[]}
 */
import Vue from 'vue'

function secondFormat (el, data) {
  const value = parseInt(data.value)
  let theTime = parseInt(value) // 秒
  let theTime1 = 0 // 分
  let theTime2 = 0 // 小时
  if (theTime > 60) {
    theTime1 = parseInt(theTime / 60)
    theTime = parseInt(theTime % 60)
    if (theTime1 > 60) {
      theTime2 = parseInt(theTime1 / 60)
      theTime1 = parseInt(theTime1 % 60)
    }
  }

  let result = '' + parseInt(theTime) // 秒
  if (theTime < 10 && theTime > 0) {
    result = '0' + parseInt(theTime) // 秒
  } else {
    result = '' + parseInt(theTime) // 秒
  }

  if (theTime1 < 10 && theTime1 > 0) {
    result = '0' + parseInt(theTime1) + ':' + result // 分，不足两位数，首位补充0，
  } else {
    result = '' + parseInt(theTime1) + ':' + result // 分
  }
  if (theTime2 > 0) {
    result = '' + parseInt(theTime2) + ':' + result // 时
  }
  el.innerText = result
}
Vue.directive('secondFormat', {
  updated: secondFormat,
  inserted: secondFormat,
  componentUpdated: secondFormat
})
