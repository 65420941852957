import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style

import { getToken } from '@/utils/auth' // 验权
// import getPageTitle from '@/utils/get-page-title'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login'] // no redirect whitelist

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start()
  if (whiteList.indexOf(to.path) !== -1) {
    next()
    return
  }
  // set page title
  // document.title = getPageTitle(Vue.$t(to.meta.title))
  // determine whether the user has logged in

  if (getToken()) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/' })
      NProgress.done()
    } else {
      try {
        // 拉取用户信息
        if (!store.getters.role) {
          await store.dispatch('user/setUserInfo')
        }
        next()
      } catch (error) {
        Message.error(error || 'Verification failed, please login again')
        next({ path: '/' })
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      // exist
      next()
    } else {
      next('/login')
    }
    NProgress.done()
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
