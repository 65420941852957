import request from '@/utils/request'

// 测评管理首页
export function main () {
  return request({
    url: '/openApi/dynExamTeacher/set/main',
    method: 'post'
  })
}
// 测评管理首页
export function getAllSet () {
  return request({
    url: '/openApi/dynExamTeacher/set/getAllSet',
    method: 'post'
  })
}

// 全局设置修改 全局设置查询页面->点击一个按钮，单个按钮设置修改）
export function allSettingUpdate (data) {
  return request({
    url: '/openApi/dynExamTeacher/set/allSettingUpdate',
    method: 'post',
    data
  })
}

// 专项练习设置查询
export function trainingSetting () {
  return request({
    url: 'openApi/dynExamTeacher/set/trainingSetting',
    method: 'post'
  })
}

// 专项练习详细设置（专项练习设置->点击一个章节，最低成章节才有设置按钮）
export function trainingSettingDetail (data) {
  return request({
    url: '/openApi/dynExamTeacher/set/trainingSettingDetail',
    method: 'post',
    data
  })
}

// 专项练习设置修改-单个章节设置修改（专项练习详细设置->单个按钮设置修改）
export function trainingSettingItemUpdate (data) {
  return request({
    url: 'openApi/dynExamTeacher/set/trainingSettingItemUpdate',
    method: 'post',
    data
  })
}

// 真题模考设置
export function examSetting () {
  return request({
    url: '/openApi/dynExamTeacher/set/examSetting',
    method: 'post'
  })
}
// 真题模考设置修改-全局设置修改（真题模考设置->点击试卷列表外的按钮，单个按钮设置修改）
export function examSettingAllSettingUpdate (data) {
  return request({
    url: '/openApi/dynExamTeacher/set/examSettingAllSettingUpdate',
    method: 'post',
    data
  })
}

// 真题模考设置修改-单个试卷设置修改（真题模考设置->点击一个试卷的按钮，单个按钮设置修改）
export function examSettingItemUpdate (data) {
  return request({
    url: '/openApi/dynExamTeacher/set/examSettingItemUpdate',
    method: 'post',
    data
  })
}

// 班级选择（动态考试测评）
export function getDynExamClassResource () {
  return request({
    url: '/openApi/overall/getDynExamClassResource',
    method: 'post'
  })
}

// 班级列表
export function classList () {
  return request({
    url: '/openApi/dynExamTeacher/classManage/list',
    method: 'post'
  })
}

// 创建班级
export function saveClass (data) {
  return request({
    url: '/openApi/dynExamTeacher/classManage/save',
    method: 'post',
    data
  })
}
// 查看班级
// export function viewClass (data) {
//   return request({
//     url: '/openApi/dynExamTeacher/classManage/view',
//     method: 'post',
//     data
//   })
// }
export function viewClass (data) {
  return request({
    url: '/openApi/dynExamTeacher/classManage/viewPage',
    method: 'post',
    data
  })
}
// 审核学生
export function checkStudent (data) {
  return request({
    url: '/openApi/dynExamTeacher/classManage/checkStudent',
    method: 'post',
    data
  })
}

// 审核通过学生
export function checkPass (data) {
  return request({
    url: '/openApi/dynExamTeacher/classManage/checkPass',
    method: 'post',
    data
  })
}
// 审核通过学生多个
export function checkPassList (data) {
  return request({
    url: '/openApi/dynExamTeacher/classManage/checkPassList',
    method: 'post',
    data
  })
}
// 审核不通过
export function checkUnPass (data) {
  return request({
    url: '/openApi/dynExamTeacher/classManage/checkUnPass',
    method: 'post',
    data
  })
}

// 审核不通过多个
export function checkUnPassList (data) {
  return request({
    url: '/openApi/dynExamTeacher/classManage/checkUnPassList',
    method: 'post',
    data
  })
}
// 添加学生
export function addStudent (data) {
  return request({
    url: '/openApi/dynExamTeacher/classManage/addStudent',
    method: 'post',
    data
  })
}

// 查找学生
export function findStudent (data) {
  return request({
    url: '/openApi/dynExamTeacher/classManage/findStudent',
    method: 'post',
    data
  })
}
// 查看学生
export function viewStudent (data) {
  return request({
    url: '/openApi/dynExamTeacher/classManage/viewStudent',
    method: 'post',
    data
  })
}
// 删除学生
export function deleteStudent (data) {
  return request({
    url: '/openApi/dynExamTeacher/classManage/deleteStudent',
    method: 'post',
    data
  })
}

// 修改班级
export function updateClass (data) {
  return request({
    url: '/openApi/dynExamTeacher/classManage/update',
    method: 'post',
    data
  })
}

// 导入验证（
export function uploadFile (data) {
  return request({
    url: '/openApi/dynExamTeacher/classManage/uploadFile',
    method: 'post',
    data
  })
}

// 导入保存（
export function saveData (data) {
  return request({
    url: '/openApi/dynExamTeacher/classManage/saveData',
    method: 'post',
    data
  })
}

// 删除班级
export function deleteClass (data) {
  return request({
    url: '/openApi/dynExamTeacher/classManage/deleteClass',
    method: 'post',
    data
  })
}

// 导出班级
export function classExport (data) {
  return request({
    url: '/openApi/dynExamTeacher/classManage/export',
    method: 'post',
    data
  })
}

export function downExpTemp (params) {
  return request({
    url: '/openApi/teacher/manage/class/downExpTemp',
    method: 'post',
    params
  })
}

// 成绩进度首页导出（web）
export function mainExport () {
  return request({
    url: '/openApi/dynExamTeacher/studyProcess/mainExport',
    method: 'post'
  })
}

// .学生报告
export function reportExport () {
  return request({
    url: '/openApi/dynExamTeacher/studyProcess/reportExport',
    method: 'post'
  })
}

// 模考详情导出（模考详情->导出按钮）
export function examPracticeExport (data) {
  return request({
    url: '/openApi/dynExamTeacher/studyProcess/examPracticeExport',
    method: 'post',
    data
  })
}
// 重置班级学生密码（不传学生id就是重置整个班级的
export function resetClassPassword (data) {
  return request({
    url: '/openApi/dynExamTeacher/classManage/resetClassPassword',
    method: 'post',
    data
  })
}
// 导出班级信息
export function totalClassExportBase64 (data) {
  return request({
    url: '/openApi/dynExamTeacher/classManage/totalClassExportBase64',
    method: 'post',
    data
  })
}
// .专项练习设置修改（专线练习设置主页上面的按钮）
export function trainingSettingUpdate (data) {
  return request({
    url: 'openApi/dynExamTeacher/set/trainingSettingUpdate',
    method: 'post',
    data
  })
}

// .真题模考详细设置（点击一个试卷）（这个传试卷id）
export function examSettingDetailByTrainingId (data) {
  return request({
    url: 'openApi/dynExamTeacher/set/examSettingDetailByTrainingId',
    method: 'post',
    data
  })
}
