<template>
  <div class="c-no-data">
    <img src="@/assets/images/noData/icom_wjxrw.png" alt="">
    <span>{{title}}</span>
  </div>
</template>

<script>
export default {
  // 暂无数据
  name: 'cNoData',
  props: {
    title: {
      type: String,
      default: '暂无数据'
    }
  }
}
</script>

<style scoped lang="scss">
  .c-no-data {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 0px 20px 0px rgba(0, 29, 77, 0.05);
    border-radius: 5px;
    span {
      font-size: 16px;
      font-weight: bold;
      color: rgba(16, 29, 55, 0.6);
      margin-top: 20px;
    }
  }
</style>
