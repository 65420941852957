const schoolBaseKey = 'schoolBase'
const schoolTerraceNameKey = 'schoolTerraceNameKey'
const schoolBase = sessionStorage.getItem(schoolBaseKey)
const schoolTerraceName = sessionStorage.getItem(schoolTerraceNameKey)

export default {
  namespaced: true,
  state: {
    schoolBase: schoolBase || '',
    schoolTerraceName: schoolTerraceName || ''
  },
  mutations: {
    SET_SCHOOLBASE: (state, data) => {
      state.schoolBase = data
    },
    SET_SCHOOLTERRACENAME: (state, data) => {
      state.schoolTerraceName = data
    }
  },
  actions: {
    setSchoolBase ({ commit }, data) {
      sessionStorage.setItem(schoolBaseKey, data)
      commit('SET_SCHOOLBASE', data)
    },
    setSchoolTerraceName ({ commit }, data) {
      sessionStorage.setItem(schoolTerraceNameKey, data)
      commit('SET_SCHOOLTERRACENAME', data)
    }
  }
}
