import Vue from 'vue'
import VueRouter from 'vue-router'
import commonRoute from './common'
import manageRoute  from './manage'

Vue.use(VueRouter)

const routes = [
  ...commonRoute,
  ...manageRoute
]

export default new VueRouter({
  routes
})
