import Cookies from 'js-cookie'

const LoginKey = 'hasLogin'
const TokenKey = 'operateadminToken'
const ClassId = 'operateClassId'
const ResourceLessonId = 'operateLessonId'
const SchoolId = 'operateSchoolId'
const TeacherId = 'operateTeacherId'
const UserId = 'operateUserId'
const ZyzhTeacherToken = 'zyzhTeacherToken'
const ExamClassId = 'exam_class_id'
const ExamTypeId = 'exam_type_id'
const intVrClassId = 'int-vr-class-id'
const vrLessonId = 'vr-lesson-id'
const abilityCompId = 'ability-comp-id'
const abilityClassId = 'ability-class-id'
const resourceApiLessonId = 'resource-api-lessonId'
const resourceApiLessonVersionId = 'resource-api-lesson-version-id'

function getCookies (str) {
  let val = Cookies.get(str)
  if (val === undefined) return ''
  return val
}

export function getToken() {
  return Cookies.get(LoginKey);
}

export function setToken() {
  return Cookies.set(LoginKey, "1")
}

export function removeToken() {
  return Cookies.remove(LoginKey)
}

export function getZyzhTeacherToken() {
  return Cookies.get(ZyzhTeacherToken);
}

export function setZyzhTeacherToken(val) {
  return Cookies.set(ZyzhTeacherToken, val)
}

export function removeZyzhTeacherToken() {
  return Cookies.remove(ZyzhTeacherToken)
}

export function getStuToken() {
  return Cookies.get(TokenKey);
}

export function setStuToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeStuToken() {
  return Cookies.remove(TokenKey)
}

export function getClassId () {
  return Cookies.get(ClassId)
}

export function setClassId (val) {
  return Cookies.set(ClassId, val)
}

export function removeClassId () {
  return Cookies.remove(ClassId)
}

export function getResourceLessonId () {
  return Cookies.get(ResourceLessonId)
}

export function setResourceLessonId (val) {
  return Cookies.set(ResourceLessonId, val)
}

export function removeResourceLessonId () {
  return Cookies.remove(ResourceLessonId)
}

export function getSchoolId () {
  return Cookies.get(SchoolId)
}

export function setSchoolId (val) {
  return Cookies.set(SchoolId, val)
}

export function removeSchoolId () {
  return Cookies.remove(SchoolId)
}

export function getTeacherId () {
  return Cookies.get(TeacherId)
}

export function setTeacherId (val) {
  return Cookies.set(TeacherId, val)
}

export function removeTeacherId () {
  return Cookies.remove(TeacherId)
}

export function getOperateUserId () {
  return Cookies.get(UserId)
}

export function setOperateUserId (val) {
  return Cookies.set(UserId, val)
}

export function removeOperateUserId () {
  return Cookies.remove(UserId)
}

export function setExamClassId (val) {
  return Cookies.set(ExamClassId, val)
}

export function getExamClassId () {
  return Cookies.get(ExamClassId)
}

export function setVrLessonId (val) {
  return Cookies.set(vrLessonId, val)
}

export function getVrLessonId () {
  return Cookies.get(vrLessonId)
}

export function setIntVrClassId (val) {
  return Cookies.set(intVrClassId, val)
}

export function getIntVrClassId () {
  return Cookies.get(intVrClassId)
}

export function setExamTypeId (val) {
  return Cookies.set(ExamTypeId, val)
}

export function getExamTypeId () {
  return Cookies.get(ExamTypeId)
}


export function setAbilityCompId (val) {
  return Cookies.set(abilityCompId, val)
}

export function getAbilityCompId () {
  return getCookies(abilityCompId)
}

export function setAbilityClassId (val) {
  return Cookies.set(abilityClassId, val)
}

export function getAbilityClassId () {
  return getCookies(abilityClassId)
}

export function setResourceApiLessonId (val) {
  return Cookies.set(resourceApiLessonId, val)
}

export function getResourceApiLessonId () {
  return getCookies(resourceApiLessonId)
}

export function setResourceApiLessonVersionId (val) {
  return Cookies.set(resourceApiLessonVersionId, val)
}

export function getResourceApiLessonVersionId () {
  return getCookies(resourceApiLessonVersionId)
}
