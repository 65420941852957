import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI, { Loading } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/permission' // permission control
import '@/assets/css/reset.css'
import '@/assets/css/base.css'
import '@/assets/css/yixin.css'
import checkApiServer from './utils/checkApiServer' // 检验request服务
import videoConf from './utils/videoHelper'
import _ from 'lodash' // 引入工具库
import './directive'
import Viewer from 'v-viewer' // 图片预览组件
import 'viewerjs/dist/viewer.css' // 图片预览组件
import cLoading from '@/components/cLoading'
import cBackGround from '@/components/cBackGround'
import cNoData from '@/components/cNoData'
import Crumb from '@/components/Crumb.vue'
import DynCrumb from '@/components/DynCrumb.vue'
import audioSlot from '@/components/audioSlot'
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'
import { videoPlayer } from 'vue-video-player'
import './assets/icon/iconfont.css'
import {default as api} from './utils/api'
import {default as apiTime} from './utils/apiTime'
import '@/icons' // icon
import VDistpicker from 'v-distpicker'
import TackVideo from '@/components/TackVideo'
import ZyAnswerLayout from '@/components/AnswerLayout'
import request from '@/utils/request'
import requestCompetition from '@/utils/requestCompetition'
import ZyMainDrag from '@/components/ZyMainDrag'
// 基础题插件
import '@/subject'
Vue.component('TackVideo', TackVideo)
Vue.component('ZyAnswerLayout', ZyAnswerLayout)
Vue.component('ZyMainDrag', ZyMainDrag)
Vue.prototype.api = api
Vue.prototype.apiTime = apiTime
Vue.config.productionTip = false
Vue.prototype._ = _
Vue.component('cLoading', cLoading)
Vue.component('cNoData', cNoData)
Vue.component('cBackGround', cBackGround)
Vue.component('crumb', Crumb)
Vue.component('dynCrumb', DynCrumb)
Vue.component('audioSlot', audioSlot)
Vue.component('videoPlayer', videoPlayer)

Vue.prototype.$practiceApi = request
Vue.prototype.$competitionApi = requestCompetition

Vue.prototype.$ct = (str, cn) => {
 return cn
}
// 加载方法
let loadingInstance = null
const startLoading = (options) => {
  if (options) loadingInstance = Loading.service(options)
  return loadingInstance
}
Vue.prototype.$loadingS = startLoading
Vue.prototype.$closeLoadingS = () => {
  if (loadingInstance) {
    loadingInstance.close()
    loadingInstance = null
  }
}
Vue.prototype.$stopPlay = function (target) {
  let audios = document.getElementsByTagName('audio');
  [...audios].forEach((item) => {
    if (item !== target) {
      item.pause()
    }
  })
  let videos = document.getElementsByTagName('video');
  [...videos].forEach((item) => {
    if (item !== target) {
      item.pause()
    }
  })
}
Vue.prototype.$htmlFormatting = function (list) {
  if (list == null) return
  list.forEach(item => {
    let temp = item.colorContentList
    if (Number(item.subject_type) === 16) {
      temp = item.webContentColorContentList
    }
    if (temp) {
      let html = ''
      temp.forEach(item2 => {
        let arr = item2.colorType.split('_')
        let styleStr = ''
        for (let i = 0; i < arr.length; i++) {
          // 颜色类型；0：无样式；1：颜色（颜色使用colorValue的值）；3下划线；4：加粗；5：斜体。多个样式_号分隔
          switch (Number(arr[i])) {
            case 1:
              styleStr += 'color : #' + item2.colorValue + ';'
              break
            case 3:
              styleStr += 'text-decoration:underline;'
              break
            case 4:
              styleStr += 'font-weight: bold;'
              break
            case 5:
              styleStr += 'font-style: oblique;'
              break
          }
        }
        html += '<span style="' + styleStr + '">' + item2.content + '</span>'
      })
      item.htmlStr = html
    }
  })
}
Vue.component('v-distpicker', VDistpicker)
Vue.use(ElementUI)
Vue.use(checkApiServer)
Vue.use(videoConf)
Vue.use(Viewer) // 图片预览组件
Viewer.setDefaults({
  Options: { 'inline': false, 'button': true, 'navbar': false, 'title': true, 'toolbar': false, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
document.oncontextmenu = function () {
  return false
}
console.clear()
