<template>
  <div class="menu-wrapper">
    <template v-for="item in routes" >
      <template  v-if="!item.hidden&&item.children">
        <!-- <router-link v-if="item.children.length===1 && !item.children[0].children" :to="item.path+'/'+item.children[0].path" :key="item.children[0].name">
          <el-menu-item :index="item.path+'/'+item.children[0].path" class='submenu-title-noDropdown'>
            <svg-icon v-if="item.children[0].meta&&item.children[0].meta.icon" :icon-class="item.children[0].meta.icon"></svg-icon>
            <span v-if="item.children[0].meta&&item.children[0].meta.title">{{item.children[0].meta.title}}</span>
          </el-menu-item>
        </router-link> -->
        <div :key="item.meta.title" v-if="item.path==='/system'&& user.role==='老师' ? false : true">
          <el-submenu :index="item.name||item.path" :key="item.meta.title">
            <template slot="title">
              <svg-icon v-if="item.meta&&item.meta.icon" :icon-class="item.meta.icon"></svg-icon>
              <span v-if="item.meta&&item.meta.title">{{item.meta.title}}</span>
            </template>

            <template v-for="child in item.children">
              <template v-if="!child.hidden">
                <!-- <sidebar-item class="nest-menu" v-if="child.children&&child.children.length>0" :routes="[child]" :key="child.path"></sidebar-item> -->
                <!-- 三级 -->
                <div v-if="child.children&&child.children.length>0 && !child.isNoChildren" :key="child.path">
                  <!-- <template v-for="t3 in child.children"> -->
                    <el-submenu :index="child.name||child.path" :key="child.meta.title">
                      <template slot="title">
                        <svg-icon v-if="child.meta&&child.meta.icon" :icon-class="item.meta.icon"></svg-icon>
                        <span v-if="child.meta&&child.meta.title">{{child.meta.title}}</span>
                      </template>
                      <template v-for="t3 in child.children">
                        <router-link :to="item.path+'/'+child.path+'/'+t3.path" :key="t3.name" class="noActive" active-class="active" tag="a">
                          <el-menu-item :index="item.path+'/'+child.path" class="elMenu">
                            <svg-icon v-if="t3.meta&&t3.meta.icon" :icon-class="t3.meta.icon"></svg-icon>
                            <span v-if="t3.meta&&t3.meta.title">{{t3.meta.title}}</span>
                          </el-menu-item>
                        </router-link>
                      </template>
                    </el-submenu>
                  <!-- </template> -->
                </div>
              
                <!-- 三级 -->
                <router-link v-else :to="item.path+'/'+child.path" :key="child.name" class="noActive" active-class="active" tag="a">
                  <el-menu-item :index="item.path+'/'+child.path" class="elMenu">
                    <svg-icon v-if="child.meta&&child.meta.icon" :icon-class="child.meta.icon"></svg-icon>
                    <span v-if="child.meta&&child.meta.title">{{child.meta.title}}</span>
                  </el-menu-item>
                </router-link>
              </template>
            </template>
          </el-submenu>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'SidebarItem',
  computed: {
    ...mapGetters([
      'user'
    ])
  },
  props: {
    routes: {
      type: Array
    }
  }
}
</script>
<style lang="scss" scoped>
.noActive.active .elMenu{
  color: rgb(64, 158, 255) !important;
}
.noActive .elMenu{
  color: #ffffff !important;
}
</style>
