// 班级列表
import request from '@/utils/request'

// 成绩进度首页
export function main () {
  return request({
    url: '/openApi/dynExamTeacher/studyProcess/main',
    method: 'post'
  })
}

// 真题模考列表
export function examIndex () {
  return request({
    url: '/openApi/dynExamTeacher/studyProcess/examIndex',
    method: 'post'
  })
}

// 模考详情 (真题模考列表->点击一条记录->模考详情）（点击一个学生到历史情况：/openApi/dynExamStudent/exam/history）
export function examPractice (data) {
  return request({
    url: '/openApi/dynExamTeacher/studyProcess/examPractice',
    method: 'post',
    data
  })
}

// 学生模考进度（点击一个学生到学生真题模考试卷列表：/openApi/dynExamStudent/exam/index）
export function examProcess (data) {
  return request({
    url: '/openApi/dynExamTeacher/studyProcess/examProcess',
    method: 'post',
    data
  })
}

// 学生报告
export function report (data) {
  return request({
    url: '/openApi/dynExamTeacher/studyProcess/report',
    method: 'post',
    data
  })
}

// 专项练习章节目录
export function trainingIndex () {
  return request({
    url: '/openApi/dynExamTeacher/studyProcess/trainingIndex',
    method: 'post'
  })
}

// 实践任务列表（专项练习章节目录->点击一个底层章节）
export function trainingList (data) {
  return request({
    url: '/openApi/dynExamTeacher/studyProcess/trainingList',
    method: 'post',
    data
  })
}

// 学生实践详情 （实践首页页面->点击一个任务->学生实践详情->点击一个学生->/openApi/dynExamStudent/answer的点赞和历史情况）（参考完成情况）
export function trainingPractice (data) {
  return request({
    url: '/openApi/dynExamTeacher/studyProcess/trainingPractice',
    method: 'post',
    data
  })
}

// 学生专项练习进度（点击学生进度按钮）（点击一个学生到学生专项练习章节列表：/openApi/dynExamStudent/index/index）
export function trainingProcess (data) {
  return request({
    url: '/openApi/dynExamTeacher/studyProcess/trainingProcess',
    method: 'post',
    data
  })
}

// .学生报告
export function reportExport () {
  return request({
    url: '/openApi/dynExamTeacher/studyProcess/reportExport',
    method: 'post'
  })
}
// 成绩进度首页导出（web）
export function mainExport () {
  return request({
    url: '/openApi/dynExamTeacher/studyProcess/mainExport',
    method: 'post'
  })
}
// 模考详情导出（模考详情->导出按钮）
export function examPracticeExport (data) {
  return request({
    url: '/openApi/dynExamTeacher/studyProcess/examPracticeExport',
    method: 'post',
    data
  })
}
// 模考详情导出（模考详情->导出按钮）
export function trainingPracticeExport (data) {
  return request({
    url: '/openApi/dynExamTeacher/studyProcess/trainingPracticeExport',
    method: 'post',
    data
  })
}
