import BaiduTranslate from '@/components/baiduTranslate/index'
export default {
  data () {
    return {
      l: 0,
      t: 0,
      browserHeight: 0,
      contentText: '',
      showTranslate: false,
      opacity: 0,
      leave: true
    }
  },
  components: { BaiduTranslate },
  watch: {
    opacity (val) {
      if (val) {
        this.$nextTick(() => {
          let obj = this.$refs['translate-box456465']
          console.log(obj.clientHeight, 'cccc')
          let height = obj.clientHeight
          if (this.browserHeight - this.t < height) {
            this.t -= height + 50
            if (this.t <= 0) {
              this.t = 20
            }
          }
        })
      }
    }
  },
  methods: {
    mouseupFu (event) {
      console.log(this.translateIsOpened, 'cccc')
      if (this.titleList && this.titleList.length > 0 && this.titleList[0].translateIsOpened === '0') return
      if (this.translateIsOpened === '0') return
      const q = this.htrans().trim()
      if (q.length <= 0) {
        return
      }
      this.l = event.clientX + 30
      this.t = event.clientY + 20
      this.browserHeight = document.body.clientHeight
      console.log('l: ', this.l, 't: ', this.t, 'browserHeight：', this.browserHeight, 'q: ', q)
      this.contentText = q
      this.showTranslate = true
      this.opacity = 0
    },
    // IE和火狐兼容性的处理函数。
    htrans () {
      if (document.selection) {
        return document.selection.createRange().text // IE
      } else {
        return window.getSelection().toString() // 标准
      }
    }
  }
}
