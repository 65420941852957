import { getInfo } from '@/api/login'
import {default as api} from '@/utils/api'
import {itemPoint} from "v-charts/lib/constants";
import Vue from 'vue'
import moment from 'moment'

const state = {
  userInfo: sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : {},
  loginInfo: sessionStorage.getItem('loginInfo') ? JSON.parse(sessionStorage.getItem('loginInfo')) : (localStorage.getItem('loginInfo') ? JSON.parse(localStorage.getItem('loginInfo')) : {}),
  schoolInfo: sessionStorage.getItem('schoolInfo') ? JSON.parse(sessionStorage.getItem('schoolInfo')) : {},
  isRecord: sessionStorage.getItem('isRecord') ? JSON.parse(sessionStorage.getItem('isRecord')) : true,
  encryptedAccount: sessionStorage.getItem('encryptedAccount') ? JSON.parse(sessionStorage.getItem('encryptedAccount')) : null,
}

const mutations = {
  SET_USER_INFO: (state, data) => {
    state.userInfo = data
  },
  SET_SCHOOL_INFO: (state, data) => {
    state.schoolInfo = data
  },
  OUT_LOGIN: (state, data) => {
    state.userInfo = {}
    state.schoolInfo = {}
  },
  SET_IS_RECORD: (state, data) => {
    state.isRecord = data
  },
  SET_LOGIN_INFO: (state, data) => {
    state.loginInfo = data
  },
  SET_ENCRYPTED_ACCOUNT: (state, data) => {
    state.encryptedAccount = data
  },
}

const actions = {
  // 获取用户信息
  async setUserInfo ({ commit }) {
   const data = await getInfo()
    const userPermission = data.userPermission
    commit('SET_USER_INFO',userPermission);
    //cookie保存登录状态,仅靠vuex保存的话,页面刷新就会丢失登录状态
    sessionStorage.setItem('userInfo', JSON.stringify(userPermission))
    // return new Promise((resolve, reject) => {
    //   api({
    //     url: '/login/getInfo',
    //     method: 'post'
    //   }).then( async data => {
    //     //储存用户信息
    //     const userPermission = data.userPermission
    //     commit('SET_USER_INFO',userPermission);
    //     //cookie保存登录状态,仅靠vuex保存的话,页面刷新就会丢失登录状态
    //     sessionStorage.setItem('userInfo', JSON.stringify(userPermission))
    //     resolve(data)
    //   }).catch(error => {
    //     reject(error)
    //   })
    // })
  },
  // 获取学校信息
  async setSchoolInfo ({ commit }, data) {
    sessionStorage.setItem('schoolInfo', JSON.stringify(data))
    commit('SET_SCHOOL_INFO', data)
  },
  outLogin({ commit }, data) {
    commit('OUT_LOGIN', data)
  },
  // 录音
  async setIsRecord ({ commit }, data) {
    sessionStorage.setItem('isRecord', JSON.stringify(data))
    commit('SET_IS_RECORD', data)
  },

  // 获取用户信息
  async setLoginInfo ({ commit }, data) {
    if (!sessionStorage.getItem('loginInfo')) {
      if (!data.classId) {
        data.classId = ''
        data.isLevel3Directory = ''
        data.lessonVersionId = ''
        data.resourceLessonId = ''
        data.resourceLessonName = ''
        data.resourceLessonType = ''
      }

      if (!data.examClassId) {
        data.examClassId = ''
        data.className = ''
      }
      if (!data.vrLessonId) {
        data.vrLessonId = ''
        data.intVrClassId = ''
      }
      if (!data.abilityCompId) {
        data.abilityCompId = ''
      }
      if (!data.abilityClassId) {
        data.abilityClassId = ''
      }
    }
    sessionStorage.setItem('loginInfo', JSON.stringify(data))
    localStorage.setItem('loginInfo', JSON.stringify(data))
    commit('SET_LOGIN_INFO', data)
  },
  async setEncryptedAccount ({ commit }, data) {
    const res = await Vue.prototype.$practiceApi({
      url: '/openApi/overall/getEncryptedAccount',
      method: 'post',
      data: {}
    })
    console.log(res,"222222");
    if (res.code !== '200') return
    sessionStorage.setItem('encryptedAccount', JSON.stringify(res.data))
    sessionStorage.setItem('_time', JSON.stringify(moment().format('hhMMYYYY')))
    commit('SET_ENCRYPTED_ACCOUNT', res.data)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
