<template>
  <div class="ans-lay">
    <div class="ans-lay__box" :style="{'height': hideTab ? '100%' : 'calc(100% - 35px)'}">
      <h1 class="ans-lay__train-name">{{trainingName}}</h1>
      <p class="ans-lay__train-remark" v-html="trainingRemark"></p>
      <div class="ans-lay__divider"></div>
      <div class="ans-lay__background">
        <slot name="background"></slot>
      </div>
    </div>
    <zy-main-drag :left="leftWidth"/>
    <div class="ans-lay__box" :style="{'height': hideTab ? '100%' : 'calc(100% - 35px)'}">
      <slot name="numAndTime" v-if="!hideTop">
        <div class="ans-lay__right-top">
          <div class="ans-lay__right-top--num" v-if="totalNum !== -1">题量：<b v-if="currentNum !== -1">{{currentNum + 1}}/</b><span>{{totalNum}}</span></div>
          <div class="ans-lay__right-top--text" v-else>{{$ct('sjpt.answerSheet2', '作答区域')}}</div>
          <div class="ans-lay__right-top--time" v-if="!isPreview && !hideTime">
            <i class="el-icon-timer"></i>
            <span v-second-format="time"></span>
          </div>
        </div>
      </slot>
      <div class="ans-lay__subject" :style="{'height': getSubjectHeight }">
        <slot name="subject"></slot>
      </div>
      <div class="ans-lay__submit" v-if="!hideSubmit">
        <slot name="submit"></slot>
      </div>
    </div>
    <div class="ans-lay__bottom" v-if="!hideTab">
      <slot name="tab"></slot>
    </div>
  </div>
</template>

<script>
// 作答布局
export default {
  name: 'AnswerLayout',
  props: {
    // 实践名称
    trainingName: {
      type: String,
      default: ''
    },
    // 实践说明
    trainingRemark: {
      type: String,
      default: ''
    },
    // 当前题
    currentNum: {
      type: Number,
      default: 0
    },
    // 总题
    totalNum: {
      type: Number,
      default: 0
    },
    // 耗时
    time: {
      type: Number,
      default: 0
    },
    isPreview: {
      type: Boolean,
      default: false
    },
    hideTime: {
      type: Boolean,
      default: false
    },
    hideSubmit: {
      type: Boolean,
      default: false
    },
    hideTab: {
      type: Boolean,
      default: false
    },
    hideTop: {
      type: Boolean,
      default: false
    },
    leftWidth: {
      type: Number,
      default: 0.45
    }
  },
  computed: {
    // 获取题目高度
    getSubjectHeight () {
      // 隐藏提交
      if (this.hideSubmit && !this.hideTop) return 'calc(100% - 50px)'
      // 隐藏顶部
      if (this.hideTop && !this.hideSubmit) return 'calc(100% - 60px)'
      // 隐藏提交 和顶部
      if (this.hideTop && !this.hideSubmit) return '100%'
      return 'calc(100% - 110px)'
    }
  }
}
</script>

<style lang="scss">
  .ans-lay {
    display: flex;
    justify-content: space-between;
    color: #101d37;
    line-height: 1.5;
    height: 100%;
    position: relative;

    &__box {
      width: calc(50% - 5px);
      display: inline-block;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
      height: calc(100% - 35px);
      overflow-y: overlay;
      &:first-child {
        margin-right: 10px;
      }
    }
    &__divider {
      display: block;
      height: 1px;
      width: calc(100% - 20px);
      margin: 15px 10px;
      background: #e5e5e5;
    }
    &__train-name {
      font-size: 16px;
      font-weight: bold;
      padding: 20px;
      padding-bottom: 10px;
    }

    &__train-remark {
      white-space: pre-wrap;
      word-break: break-word;
      word-wrap: break-word;
      padding: 0 20px;
    }

    &__background {
      padding: 0 15px;
    }

    &__right-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 20px;
      border-bottom: 1px solid #e5e5e5;
      height: 50px;
      &--text {
        font-size: 16px;
        color: #000000;
        font-weight: bold;
      }
      &--num {
        color: #91959a;
        & > span  {
          color: #f86f33;
        }
        & > b  {
          color: #f86f33;
          font-size: 26px;
        }
      }
      &--time {
        & > i {
          font-size: 20px;
          color: #1CACFF;
          padding-right: 5px;
        }
      }
    }

    &__subject {
      /*height: calc(100% - 110px);*/
      overflow-y: overlay;
    }
    &__submit {
      border-top: 1px solid #e5e5e5;
      height: 60px;
      padding: 10px;
    }

    &__bottom {
      width: 100%;
      position: absolute;
      bottom: 0;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
      height: 35px;
      display: flex;
      align-items: center;
    }
  }
</style>
