<template>
  <div class="tack-video" ref="tack-video">
    <video-player
      v-once
      @play="onPlay"
      @ended="setFirst"
      :playsinline="true"
      @timeupdate="updateTime"
      @canplaythrough="canplayThrough"
      :options="$videoConf(fileUrl)"
      class="video-player vjs-custom-skin"/>
    <div class="tack-box" v-show="tackIndex > -1">
      {{ tackIndex > -1 ? tackList[tackIndex].text : ''}}
    </div>
  </div>
</template>

<script>

import axios from 'axios'
import { mapGetters } from 'vuex'
export default {
  name: 'TackVideo',
  data () {
    return {
      $video: null,
      tackList: [],
      tackIndex: -1
    }
  },
  props: {
    fileUrl: {
      type: String,
      default: ''
    },
    vttUrl: {
      type: String,
      default: ''
    },
    subtitlesIsOpened: {
      type: String,
      default: '0'
    }
  },
  computed: {
    ...mapGetters(['isRecord'])
  },
  methods: {
    onPlay (event, refName) {
      // 录中不能播放
      if (!this.isRecord) {
        this.$stopPlay()
        this.$message('正在录音')
        return
      }
      if (!refName) {
        this.$stopPlay(this.$video)
      }
    },
    setFirst (event) {
      this.$emit('ended', event)
    },
    // (A)①获取文本文件方法(传统javascript实现AJAX写法)
    // 将时间转化为秒
    toSeconds (t) {
      var s = 0.0
      if (t) {
        var p = t.split('.')[0].split(':')
        s += Number(p[0]) * 60 * 60
        s += Number(p[1]) * 60
        s += Number(p[2])
        s = s + '.' + t.split('.')[1]
      }
      return s
    },
    updateTime (event) {
      this.$emit('timeupdate', event)
      if (this.subtitlesIsOpened === '1') {
        let time = this.$video.currentTime
        this.tackIndex = -1
        for (let i = 0; i < this.tackList.length; i++) {
          if (time >= this.tackList[i].starTime && time <= this.tackList[i].endTime) {
            this.tackIndex = i
          }
        }
      }
      // console.log(this.$video.currentTime)
    },
    canplayThrough (event) {
      this.$emit('canplaythrough', event)
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$video = this.$el.getElementsByTagName('video')[0]
    })
    if (this.subtitlesIsOpened === '0' || !this.vttUrl) return
    this.$nextTick(() => {
      const objRefNode = this.$el.getElementsByClassName('video-js')[0]
      const vjsControlBar = objRefNode.getElementsByClassName('vjs-poster')[0]
      const tackBox = this.$el.getElementsByClassName('tack-box')[0]
      objRefNode.insertBefore(tackBox, vjsControlBar)
    })
    axios({
      method: 'GET',
      url: `https://${process.env.VUE_APP_HTTP_DOMAIN}.zyamoy.com/${this.vttUrl}`
    }).then(res => {
      if (Number(res.status) === 200) {
        var str = res.data
        let arr = str.split('\n')
        arr = arr.filter(item => {
          if (item.trim() !== 'WEBVTT' && item.trim() !== '') return item
        })
        const tackList = []
        arr.forEach(item => {
          if (item.indexOf(' --> ') !== -1) {
            let data = {}
            let str = item.split(' --> ')
            console.log(str)
            data.starTime = this.toSeconds(str[0].trim())
            data.endTime = this.toSeconds(str[1].trim())
            tackList.push(data)
          } else {
            tackList[tackList.length - 1].text = item
          }
        })
        this.tackList = tackList
      }
    })
    // var str = 'WEBVTT  \n' +
    //   '\n' +
    //   '00:00:00.000 --> 00:00:01.900\n' +
    //   'The two sides have agreed on the\n' +
    //   '\n' +
    //   '00:00:02.000 --> 00:00:05.300\n' +
    //   'key issues and the negotiation is drawing to a close.\n' +
    //   '\n' +
    //   '00:00:05.400 --> 00:00:07.600\n' +
    //   'When the Saudi party proposes that \n' +
    //   '\n' +
    //   '00:00:07.700 --> 00:00:11.200\n' +
    //   'the beneficiary should bearall the bank charges incurred \n' +
    //   '\n' +
    //   '00:00:11.300 --> 00:00:13.000\n' +
    //   'except from the issuing bank,\n' +
    //   '\n' +
    //   '00:00:13.100 --> 00:00:15.900\n' +
    //   'David hesitates and then accepts it.\n' +
    //   '\n' +
    //   '00:00:16.000 --> 00:00:20.300\n' +
    //   'Now that all problems are solved.\n' +
    //   '\n' +
    //   '00:00:20.400 --> 00:00:22.400\n' +
    //   'James, please do a summary.\n' +
    //   '\n' +
    //   '00:00:23.000 --> 00:00:27.100\n' +
    //   'Ok. Abidin Trading Est has placed an order of 30,000 yards  \n' +
    //   '\n' +
    //   '00:00:27.200 --> 00:00:29.400\n' +
    //   'for the silk charmeuse fabric 14654 with \n' +
    //   '\n' +
    //   '00:00:29.500 --> 00:00:33.200\n' +
    //   'the item number LH-202: 100% mulberry silk,\n' +
    //   '\n' +
    //   '00:00:33.300 --> 00:00:37.100\n' +
    //   '19 mm, 45-inch width, price ... \n' +
    //   '\n' +
    //   '00:00:37.200 --> 00:00:38.900\n' +
    //   'Do you have any objections?\n' +
    //   '\n' +
    //   '00:00:39.000 --> 00:00:40.000\n' +
    //   'No objections.\n' +
    //   '\n' +
    //   '00:00:40.100 --> 00:00:41.200\n' +
    //   'That\'s good. \n' +
    //   '\n' +
    //   '00:00:41.300 --> 00:00:44.600\n' +
    //   'Please take a break in the lounge and then sign the contract.\n' +
    //   '\n' +
    //   '00:00:46.300 --> 00:00:48.500\n' +
    //   'I hope we have a good cooperation. \n' +
    //   '\n' +
    //   '00:00:48.600 --> 00:00:50.100\n' +
    //   'Good cooperation. \n' +
    //   '\n' +
    //   '00:00:50.200 --> 00:00:54.300\n' +
    //   'After a short break, the two parties have a signing ceremony.\n' +
    //   '\n' +
    //   '\n' +
    //   '\n' +
    //   '\n' +
    //   '\n' +
    //   '\n' +
    //   '\n' +
    //   '\n' +
    //   '\n' +
    //   '\n' +
    //   ''
    // let arr = str.split('\n')
    // arr = arr.filter(item => {
    //   if (item.trim() !== 'WEBVTT' && item.trim() !== '') return item
    // })
    // const tackList = []
    // arr.forEach(item => {
    //   if (item.indexOf(' --> ') !== -1) {
    //     let data = {}
    //     let str = item.split(' --> ')
    //     console.log(str)
    //     data.starTime = this.toSeconds(str[0].trim())
    //     data.endTime = this.toSeconds(str[1].trim())
    //     tackList.push(data)
    //   } else {
    //     tackList[tackList.length - 1].text = item
    //   }
    // })
    // this.tackList = tackList
  }
}
</script>

<style scoped lang="scss">
  .tack-video {
    position: relative;
    .tack-box {
      width: 100%;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 0px;
      left: 0;
      font-size: 2em;
      color: #FFFFFF;
      text-align: center;
      white-space: pre-wrap;
      word-break: break-word;
      transition: all 0.5s;
      background-color: rgba(43, 51, 63, 0.7);
    }
    .vjs-user-active {
      .tack-box {
        bottom: 42px;
      }
    }
  }
</style>
