<template>
  <el-menu
    :default-active="menuIndex"
    class="el-menu-vertical-demo"
    @open="handleOpen"
    @select="handleSelect"
    :unique-opened="true"
    background-color="#344157"
    text-color="#FFFFFF"
    active-text-color="#72B6FF">
    <!-- 1级目录 -->
    <template v-for="(item, i) in menuList">
      <el-submenu :index="`${i}`" :key="`111_${i}`" v-if="item.hasChildren" :popper-append-to-body="true">
        <template slot="title"><i class="el-icon-menu" style="color: white"/>{{item.menu}}</template>
        <!-- 2级目录 -->
        <template v-for="(item2, i2)  in item.children">
          <el-submenu :index="`${i}-${i2}`" :key="`111_${i2}`" v-if="item2.hasChildren">
            <template slot="title"><i class="el-icon-menu" style="color: white"/>{{item2.menu}}</template>
            <!-- 3级目录 -->
            <template v-for="(item3, i3)  in item2.children">
              <el-menu-item :index="`${i}-${i2}-${i3}`" :key="`333_${i3}`" >
                <i class="el-icon-s-help" style="color: white"/> {{item3.menu}}
              </el-menu-item>
            </template>
          </el-submenu>
          <el-menu-item :index="`${i}-${i2}`" :key="`222_${i2}`" v-else>
            <i class="el-icon-s-help" style="color: white"/> {{item2.menu}}
          </el-menu-item>
        </template>
      </el-submenu>
      <el-menu-item :index="`${i}`" :key="`111_${i}`" v-else>
        <i class="el-icon-s-help" style="color: white"/> {{item.menu}}
      </el-menu-item>
    </template>
  </el-menu>
</template>
<script>
  import {mapGetters} from 'vuex'
  import SidebarItem from './SidebarItem'
  import ScrollBar from '@/components/ScrollBar'
  export default {
    data () {
      return {
        menuIndex: '0',
        menuList: null
//        menuList: [
//          {
//            path: '/system',
//            menu: '系统管理',
//            hasChildren: true,
//            children: [
//              { path: 'user', menu: '用户管理'},
//              { path: 'menuPermission', menu: '菜单权限'},
//              { path: 'role', menu: '角色权限'},
//              { path: 'school', menu: '学校信息管理'},
//              { path: 'schoolAdmin', menu: '学校管理员管理'},
//              { path: 'filemanage', menu: '文件上传管理'}
//            ]
//          },
//          {
//            path: '/practice',
//            menu: '专业实践管理',
//            hasChildren: true,
//            children: [
//              {
//                path: 'teaching',
//                menu: '教学管理',
//                hasChildren: true,
//                children:[
////                  { path: 'classManage', menu: '班级管理' },
////                  { path: 'courseSetting', menu: '课程设置' },
//                  { path: 'score', menu: '成绩管理' },
//                ]
//              },
//              {
//                path: 'schedule',
//                menu: '教学进度',
//                hasChildren: true,
//                children:[
//                  { path: 'trends', menu: '学习动态查询'},
//                  { path: 'practicalSkills', menu: '实践技能进度'},
//                  { path: 'caseAnalyse', menu: '案例分析进度'},
//                  { path: 'interaction', menu: '互动实训进度'},
//                  { path: 'multipleCase', menu: '综合案例进度'},
//                  { path: 'task', menu: '教学任务进度'}
//                ]
//              },
//              {
//                path: 'analysis',
//                menu: '教学分析',
//                hasChildren: true,
//                children:[
//                  { path: 'learning', menu: '学情分析'},
//                ]
//              },
//            ]
//          }
//        ]
      }
    },
    components: {SidebarItem, ScrollBar},
    computed: {
      ...mapGetters([
        'permission_routers',
        'sidebar',
        'manageRoute',
        'userInfo',
        'isEval',
        'isVr'
      ]),
      isCollapse() {
        return !this.sidebar.opened
      }
    },
    methods: {
      handleOpen (val) {
        console.log(val)
        if (this.menuList.length <= 0) return
        let array = this._.isArray(val) ? val : [val]
        let key = array[array.length - 1].split('-')
        let tempIndex = key[0]
        let obj = this.menuList[key[0]]
        let i = 1
        let path = obj.path
        while (true) {
          if (obj.hasChildren) {
            obj = obj.children[key[i] || 0]
            path = path + '/' + obj.path
            tempIndex = tempIndex + '-' + (key[i] || '0')
            i++
          } else break
        }
        this.menuIndex = tempIndex
        sessionStorage.setItem('Sidebar:menuIndex', this.menuIndex)
        this.$router.push({path: path})
      },
      // 最近一级选中事件
      handleSelect (val) {
        this.menuIndex = val
        let key = this.menuIndex.split('-')
        let obj = this.menuList[key[0]]
        let path = obj.path
        for (let i = 1; i < key.length; i++) {
          obj = obj.children[key[i]]
          path = path + '/' + obj.path
        }
        sessionStorage.setItem('Sidebar:menuIndex', this.menuIndex)
        this.$router.push({path: path})
      }
    },
    created() {
      if(this.isEval){
        this.menuList = this.userInfo.menuList.filter(item => {
          return  item.permissionCode == 1
        })
      }else if(this.isVr){
        this.menuList = this.userInfo.menuList.filter(item => {
          return  item.permissionCode == 2
        })
      }else{
        this.menuList = this.userInfo.menuList.filter(item => {
          return  item.permissionCode == 0
        })
      }
      this.menuIndex = sessionStorage.getItem('Sidebar:menuIndex') || '0'
      this.handleOpen(this.menuIndex )
    },
    watch: {
      isEval () {
//        alert("1")
        if(this.isEval){
          this.menuList = this.userInfo.menuList.filter(item => {
           return  item.permissionCode == 1
          })
        }else if(this.isVr){
          this.menuList = this.userInfo.menuList.filter(item => {
            return  item.permissionCode == 2
          })
        }else{
          this.menuList = this.userInfo.menuList.filter(item => {
            return  item.permissionCode == 0
          })
        }
        this.handleOpen('0')
      },
      isVr () {
        if(this.isVr){
          this.menuList = this.userInfo.menuList.filter(item => {
            return  item.permissionCode == 2
          })
        }else if(this.isEval){
            this.menuList = this.userInfo.menuList.filter(item => {
              return  item.permissionCode == 1
            })
        }else{
          this.menuList = this.userInfo.menuList.filter(item => {
            return  item.permissionCode == 0
          })
        }
        this.handleOpen('0')
      }
    }
  }
</script>
<style lang="scss" disabled>
 .el-menu-vertical-demo {
   height: 100%;
   .el-submenu {

   }

 }
</style>
