<template>
  <div class="crumb">
    <div class="flex-row">
      <div style="display: flex;align-items: center;">
        <div style="font-size: 14px;color: #91959A;width: 70px;"></div>
      </div>
      <!--<el-button size="small" v-if="isShowBack" @click="back(pageurl)">返回</el-button>-->
    </div>
    <div class="class-info">
      <div class="class-nav" v-if="classInfo">
        <span>班级名称：</span>
        <span v-if="classInfo.className">{{classInfo.className}}</span>
        <el-dropdown v-else @command="handleCommand" >
          <span class="el-dropdown-link" style="color: #409eff;font-weight: bold; font-size: 16px" >
            {{selectValue}}<i class="el-icon-caret-bottom el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="item in selectData" :command="item.classId" :key="item.classId">{{item.className}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <span><b>|</b>学生人数：{{classInfo.studentNumber}}人</span>
        <span v-if="classInfo.examTypeName"><b>|</b>试卷类型：{{schoolInfo.examTypeName}}</span>
      </div>
      <el-button size="mini" class='export-btn' @click="resultExport" v-if="showExport" >导出</el-button>
    </div>
    <el-dialog
      appendToBody=""
      title="导出"
      top="38vh"
      :visible.sync="dialogVisible"
      width="500px">
      <div class="exam-practice-export">
        <el-radio-group v-model="radio">
          <el-radio :label="1">第一次作答成绩</el-radio>
          <el-radio :label="0">最高分作答成绩</el-radio>
          <el-radio :label="2">平均分</el-radio>
        </el-radio-group>
        <div>
          <el-button style="margin-top: 30px;width: 100px" type="primary" @click="examPracticeExport">确认</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { setExamClassId } from '@/utils/auth'
import { getDynExamClassResource } from '@/api/dynExam.js'
import { reportExport, mainExport, examPracticeExport } from '@/api/dynExam.js'
import { timestampToTime } from '@/utils/dateFomate'

export default {
  data () {
    return {
      levelList: null,
      times: null,
      selectData: [],
      selectValue: '',
      dialogVisible: false,
      radio: 1
    }
  },
  props: {
//    isShowBack: {
//      type: Boolean,
//      default: false
//    },
//    pageurl: {
//      type: String,
//      default: ''
//    },
    classInfo: {
      type: Object,
      default: null
    },
    showExport: {
      type: Boolean,
      default: false
    },
    resourceExamPaperName: {
      type: String,
      default: ''
    },
    resourceExamPaperId: {
      type: String,
      default: ''
    }
  },
  watch: {
    $route () {
      this.getBreadcrumb()
    }
  },
  computed: {
    ...mapGetters(['schoolInfo'])
  },
  methods: {
    ...mapActions('user', ['setSchoolInfo']),
    getBreadcrumb () {
      let array = []
      if (this.$route.query.headList && !this._.isArray(this.$route.query.headList)) {
        array.push(this.$route.query.headList)
      } else {
        array = this.$route.query.headList || []
      }
      this.levelList = array.concat(this.$route.meta.headList || [])
    },
//    back (pageurl) {
//      if (pageurl !== '') {
//        this.$router.push({ name: pageurl })
//        return
//      }
//      history.go(-1)
//    },
    handleCommand (classId) {
      this.selectData.forEach(item => {
        if (item.classId === classId) {
          this.selectValue = item.className
          setExamClassId(classId)
          this.setSchoolInfo(Object.assign(this.schoolInfo, {
            examClassId: classId,
            examClassName: item.className
          }))
        }
      })
    },
    // 获取班级列表
    async getDynExamClassResource () {
      this.selectData = this.$checkApiServer(await getDynExamClassResource())
    },
    // 成绩导出
    async resultExport () {
      if (this.$route.name === 'examErSieTestDetails') {
        this.dialogVisible = true
        return
      }
      let api = null
      let str = this.selectValue
      if (this.$route.name === 'examProgressResult') {
        api = mainExport
        str += '-成绩进度'
      } else {
        api = reportExport
        str += '-学生报告'
      }
      let uri = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + this.$checkApiServer(await api())
      let link = document.createElement('a')
      link.href = uri
      link.download = `${str}-${timestampToTime(new Date().getTime()).split(' ')[0]}.xlsx`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },

    // 真题模考 导出
    async examPracticeExport () {
      let str = '真题模考-' + this.classInfo.className + '-' + this.resourceExamPaperName
      if (this.radio === 0) str += '-第一次作答'
      if (this.radio === 1) str += '-最高分'
      if (this.radio === 2) str += '-平均分'

      let uri = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + this.$checkApiServer(await examPracticeExport({
        exportType: this.radio, // (string)导出类型。0：最高分（默认）；1：首次；2：平均分
        resourceExamPaperId: this.resourceExamPaperId // (string)试卷id
      }))
      let link = document.createElement('a')
      link.href = uri
      link.download = `${str}-${timestampToTime(new Date().getTime()).split(' ')[0]}.xlsx`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },

  },
  async mounted () {
    const times = this.$route.query.times
    if (times) this.times = times
    await this.getDynExamClassResource()
    this.selectData.forEach(item => {
      if (item.classId === this.schoolInfo.examClassId) {
        this.selectValue = item.className
      }
    })
  },
  created () {
    this.getBreadcrumb()
  }
}
</script>

<style lang="scss" scoped>
  .exam-practice-export {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    flex-direction: column;
  }
  .export-btn {
    height: 32px;
  }
  .crumb {
    position: relative;
    .el-breadcrumb {
      display: block;
      font-size: 14px;
      line-height: 50px;
      width: 1000px;
      .no-redirect {
        color: #91959A;
        font-size: 14px;
        cursor: text;
      }
      .active {
        color: #101D37;
      }
    }
    .class-info {
      top: 50%;
      transform: translateY(-44%);
      right: 65px;
      display: flex;
      align-items: center;
      /*border: 1px solid #df1263;*/
      /*width: 600px;*/
      .class-nav {
        font-size: 14px;
        color: #606266;
        margin-right: 10px;
        b {
          margin: 0 9px;
          font-weight: 700;
          color: #C0C4CC;
        }
        .el-dropdown-link {
          cursor: pointer;
        }
      }

    }
  }
</style>
