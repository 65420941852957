<template>
  <el-container>
    <el-header>
      <header-con></header-con>
    </el-header>
    <el-container>
      <el-aside width="250px" v-if="isRouterAlive1">
        <sidebar class="sidebar-container"></sidebar>
      </el-aside>
      <el-main>
        <router-view v-if="isRouterAlive"></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { Navbar, Sidebar, AppMain, headerCon } from '@/views/layout/components'
import {mapGetters} from "vuex";
export default {
  name: 'layout',
  data() {
    return {
      isRouterAlive: true,
      isRouterAlive1: true
    }
  },
  components: {
    Navbar,
    Sidebar,
    AppMain,
    headerCon
  },
  computed: {
    ...mapGetters(['schoolInfo','isEval','isVr']),
    sidebar() {
      return this.$store.state.app.sidebar
    }
  },
  watch: {
    schoolInfo: function (val) {
      this.isRouterAlive =  false
      setTimeout(() => {
          this.isRouterAlive = true
        },500)
//      this.$nextTick(() => {
//        this.isRouterAlive = true
//      })
    },
    isEval: function (val) {
      console.log(11)
      this.isRouterAlive1 =  false
      this.$nextTick(() => {
        this.isRouterAlive1 = true
      })
    },
    isVr: function (val) {
      console.log(22)
      this.isRouterAlive1 =  false
      this.$nextTick(() => {
        this.isRouterAlive1 = true
      })
    },
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "src/styles/mixin.scss";
.el-container{
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
