<template>
  <div class="baidu-translate" v-if="trans_result"  @click.stop="">
    <div class="top-box">
      <div>中语取词</div>
      <i class="el-icon-close cursor" @click="closeFu"></i>
    </div>
    <div style="max-height: 600px; overflow-y: auto;">
      <div class="content-box" v-for="(obj, key) in trans_result" :key="`obj_${key}`">
        <div class="title-box">
          {{obj.src}}<img src="./imgs/0002.svg" alt="" v-if="obj.src_tts" @click.stop="openAudio(`audio_${key}`)">
        </div>
        <audio :ref="`audio_${key}`" style="display: none" :src="obj.src_tts" v-if="obj.src_tts"></audio>
        <div class="symbols-box" v-for="(item, index) in obj.symbols" :key="`item_${key}_${index}`">
          <div class="ph-box">
            <div class="ph_en" v-if="item.word_symbol">
              <span>音</span>
              <span>[{{item.word_symbol}}]</span>
            </div>
            <div class="ph_en" v-if="item.ph_en">
              <span>英</span>
              <span>[{{item.ph_en}}]</span>
            </div>
            <div class="ph_en" style="margin-left: 20px"  v-if="item.ph_am">
              <span>美</span>
              <span>[{{item.ph_am}}]</span>
            </div>
          </div>
          <div class="parts-box" v-for="(item2, index2) in item.parts" :key="`item2_${key}_${index}_${index2}`">
            <div><span>{{item2.part}}</span>{{item2.meansStr}}</div>
          </div>
        </div>
        <div class="result-box" v-if="obj.symbols.length === 0">{{obj.dst}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { baiduTranslate } from '@/api/overall'
export default {
  name: 'baiduTranslate',
  data () {
    return {
      trans_result: null
    }
  },
  props: {
    contentText: {
      type: String,
      default: () => ''
    },
    showTranslate: {
      type: Boolean,
      default: () => false
    },
    opacity: {
      type: Number,
      default: () => 0
    }
  },
  methods: {
    async baiduTranslate () {
      const data = this.$checkApiServer(await baiduTranslate({
        content: this.contentText
      }))
      this.trans_result = data.trans_result // 翻译内容
      this.$emit('update:opacity', 1)
    },
    closeFu () {
      this.$emit('update:showTranslate', false)
      this.$emit('update:opacity', 0)
    },
    openAudio (tag) {
      this.$refs[tag][0].play()
    }
  },
  created () {
    this.baiduTranslate()
  }
}
</script>

<style scoped lang="scss">
  .baidu-translate {
    min-width: 350px;
    max-width: 500px;
    background: white;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    .top-box {
      width: 100%;
      height: 30px;
      padding: 0 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #999999;
      background: #f8f8f8;
      border-bottom:1px solid #DCDFE6;
    }
    .content-box {
      padding: 10px 20px;
      .title-box {
        width: 100%;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 10px;
        display: inline-block;
        img {
          width: 20px;
          height: 20px;
          margin-left: 10px;
          cursor: pointer;
          display: inline-block;
          vertical-align: middle;
        }
      }
      .symbols-box {
        .ph-box {
          display: flex;
          align-items: center;
          margin: 5px 0;
          .ph_en {
            display: flex;
            align-items: center;
            font-size: 14px;
            line-height: 20px;
            span:first-child {
              font-weight: bold;
              margin-right: 5px;
            }
            img {
              width: 20px;
              height: 20px;
              margin-left: 10px;
              cursor: pointer;
            }
          }
        }
        .parts-box {
          display: flex;
          >div {
            font-size: 14px;
            line-height: 25px;
            color: #606266;
            box-sizing: border-box;
          }
        }
      }
      .result-box {
        font-size: 14px;
        line-height: 21px;
        color: #606266;
      }
    }
  }
</style>
