/**
 * create by hp
 * date 2020/2/26 17:33
 * @type {string[]}
 */
import Vue from 'vue'

// todo 后续需要加上一个功能，就是可以传入fun，根据fun的规则来进行路由，增加灵活性
Vue.directive('back', {
  bind: function (el, data) {
    el.addEventListener('click', () => {
      let routerIndex = data.value || -1
      routerIndex = parseInt(routerIndex)
      history.go(routerIndex)
    })
  }
})
