import request from '@/utils/api'

// 用户登录
export function getInfo () {
  return request({
    url: '/login/getInfo',
    method: 'post'
  })
}

// 学校信息
export function auth (data) {
  return request({
    url: '/login/auth',
    method: 'post',
    data
  })
}
