const setSystemBaseKey = `SET_SYSTEMBASE`
let baseInfo = sessionStorage.getItem(setSystemBaseKey)
if (baseInfo) baseInfo = JSON.parse(baseInfo)
export default {
  namespaced: true,
  state: {
    systemBase: baseInfo
  },
  mutations: {
    SET_SYSTEMBASE: (state, data) => {
      state.systemBase = data
    }
  },
  actions: {
    setSystemBase ({ commit }, data) {
      sessionStorage.setItem(setSystemBaseKey, JSON.stringify(data))
      commit('SET_SYSTEMBASE', data)
    }
  }
}
