/**
 * create by hp
 * date 2020/7/3
 * @type {string[]}
 */

import Vue from 'vue'

Vue.directive('enLineShow', {
  bind: function (el, data) {
    el.style.wordBreak = 'break-word'
    el.style.whiteSpace = 'pre-wrap'
  }
})
