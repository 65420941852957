import axios from 'axios'
import {Message, MessageBox} from 'element-ui'
import {
  getToken,
  getStuToken,
  getClassId,
  getResourceLessonId,
  getSchoolId,
  getTeacherId,
  removeToken
} from '@/utils/auth'
import store from '../store'
// // 创建axios实例
// const service = axios.create({
//   baseURL: process.env.VUE_APP_BASE_URL, // api的base_url
//   timeout: 95000                  // 请求超时时间2
// })

axios.defaults.baseURL = process.env.NODE_ENV === 'development'
  ? '/operate'
  : process.env.VUE_APP_BASE_API
// create an axios instance
const service = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? '/operate'
      : process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 600000 // request timeout
})

// request拦截器
service.interceptors.request.use(config => {
  config.headers['operateToken'] = getStuToken() // token
  config.headers['operateClassId'] = getClassId() // 班级ID
  config.headers['operateLessonId'] = getResourceLessonId() // 课程ID
  config.headers['operateSchoolId'] = getSchoolId() // 学校ID
  config.headers['operateTeacherId'] = getTeacherId() // 老师ID
  config.url = 'wapi/' + config.url
  return config
}, error => {
  // Do something with request error
  console.error(error) // for debug
  Promise.reject(error)
})
// respone拦截器
service.interceptors.response.use(
  response => {
    const res = response.data;
    if (res.code == '1000') {
      return res;
    }
    if (res.code == '200') {
      return res.data;
    } else if (res.code == "20011") {
      Message({
        showClose: true,
        message: res.message,
        type: 'error',
        duration: 500,
        onClose: () => {
          removeToken()
          sessionStorage.clear()
          localStorage.clear()
          store.dispatch('user/outLogin').then(() => {
            location.reload()// 为了重新实例化vue-router对象 避免bug
            this.$router.push({name: 'login'})
          })
        }
      });
      return Promise.reject("未登录")
    } else {
      Message({
        message: res.message,
        type: 'error',
        duration: 3 * 1000
      })
      return Promise.reject(res)
    }
  },
  error => {
    console.error('err' + error)// for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 3 * 1000
    })
    return Promise.reject(error)
  }
)
export default service

