import Vue from 'vue'

// 字幕api
const subtitlesApi = process.env.NODE_ENV === 'development'
  ? '/vtt'
  : process.env.VUE_APP_BASE_API.replace('/api', '')
Vue.prototype.$subtitlesApi = subtitlesApi

// 上传api
const appservermavenApi = process.env.NODE_ENV === 'development'
  ? '/appservermaven/?dir='
  : process.env.VUE_APP_SERVERMAVEN + '?dir='
Vue.prototype.$appservermavenApi = appservermavenApi

// 翻译
// const lang = Vue.prototype.$ct

// 开启本地基础组件 需要匹配好 zyzh_subject 项目的路径
let bug = false
if (process.env.NODE_ENV === 'development' && bug) {
  const subject = require('../../../zyzh_subject/src/index.js')
  // console.log('3333', subject)
  Vue.use(subject)
  Vue.mixin(subject.subjectTypesMixin)
  Vue.prototype.$practicalMap = subject.practicalMap
} else {
  // 外包链接题目组件
  if (window.zysubject) {
    Vue.use(window.zysubject)
    Vue.mixin(window.zysubject.subjectTypesMixin)
    Vue.prototype.$practicalMap = window.zysubject.practicalMap
  }
}
