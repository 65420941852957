<template>
  <div>
    <!-- 任务背景 -->
    <back-ground v-if="!isDialog" @setFirst="setFirst" :title-list="titleList"/>
    <!-- 有弹框任务背景 -->
    <el-dialog
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      append-to-body
      width="900px"
      :title="title"
      v-else>
      <back-ground v-if="dialogVisible" style="max-height: 550px;overflow-y: auto"  :title-list="titleList"/>
      <span slot="footer" class="dialog-footer" style="display: flex;align-items: center;justify-content: flex-end">
        <img src="@/assets/images/icon_tlyw_70.png" @click="infoData.showListeningText = !infoData.showListeningText" v-if="Number(infoData.listeningTextIsOpened) === 1" style="width: 40px;margin-right: 20px;cursor: pointer;" alt="听力原文">
        <el-button @click="handleClose">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 有弹框任务背景 -->
    <el-dialog
      :visible.sync="infoData.showListeningText"
      :before-close="handleClose2"
      append-to-body
      width="900px"
      title="原文">
      <div style="font-size: 14px;line-height: 20px;color: #101D37" class="zyzh-text-formatting">{{infoData.listeningText}}</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose2">关 闭</el-button>
      </span>
    </el-dialog>
  </div>

</template>

<script>
import BackGround from './backGround'
export default {
  // 没有弹窗的背景
  name: 'cBackGround',
  components: { BackGround },
  data () {
    return {}
  },
  props: {
    title: { // 弹框标题
      type: String,
      default: '任务背景'
    },
    titleList: { // 背景信息
      type: Array,
      default: () => []
    },
    dialogVisible: { // 弹框显示隐藏
      type: Boolean,
      default: () => false
    },
    infoData: {
      type: Object,
      default: () => {
        return {
          showListeningText: false,
          listeningText: '',
          listeningTextIsOpened: 0
        }
      }
    },
    isDialog: { // 是弹框
      type: Boolean,
      default: () => false
    },
    isFirst: {
      type: String,
      defaults: () => ''
    }
  },
  methods: {
    // 关闭弹窗
    handleClose () {
      this.$emit('update:dialogVisible', false)
    },
    // 关闭弹窗
    handleClose2 () {
      this.infoData.showListeningText = false
    },
    setFirst () {
      this.$emit('update:isFirst', '0')
    }
  },
  watch: {
    titleList () {
      this.$htmlFormatting(this.titleList)
    }
  },
  created () {
    this.$htmlFormatting(this.titleList)
  }
}
</script>

<style scoped lang="scss">
</style>
