/**
 * create by hp
 * date 2020/7/24 17:33
 * @type {string[]}
 */
import Vue from 'vue'

let style = function (el, data) {
  let val = data.value || {}
  for (let key in val) {
    el.style[key] = val[key]
  }
}

// 通过js来写css，我们还需要提供一个css的所有的变量名，以及常用的变量值
Vue.directive('style', {
  bind: style,
  componentUpdated: style
})
