/**
 * create by hp
 * date 2020/2/26 17:33
 * @type {string[]}
 */

// ps: 已经过时，请使用 ./enLineShow.js
import Vue from 'vue'

Vue.directive('wordKeepInLine', {
  bind: function (el, data) {
    el.style.wordBreak = 'keep-all'
  }
})
