import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import getters from './getters'
import user from './modules/user'
import schoolBase from './modules/schoolBase'
import loadingBase from './modules/loadingBase'
import vr from './modules/vr'
import evaluate from '@/store/modules/evaluate'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    user,
    schoolBase,
    loadingBase,
    vr,
    evaluate
  },
  getters
})

export default store
