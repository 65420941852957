import request from '@/utils/api'
import zyrequest from '@/utils/request'

// 获取用户详细信息
export function getUserDetail (data) {
  return request({
    url: '/openApi/overall/getUserDetail',
    method: 'post',
    data
  })
}

// 全局--班级课程选择
export function getClassResource (data) {
  return request({
    url: '/openApi/overall/getClassResource',
    method: 'post',
    data
  })
}

// 全局--查找题目信息
export function findSubject (data) {
  return request({
    url: '/openApi/overall/findSubject',
    method: 'post',
    data
  })
}

// 查询题目 ，有分页的那种
export function findSubjectPage (data) {
  return request({
    url: '/openApi/overall/findSubjectPage',
    method: 'post',
    data
  })
}

// 全局--上传
export function upload (data) {
  return request({
    url: '/overall/upload',
    method: 'post',
    data
  })
}

export function speakTest (data) {
  return request({
    url: '/openApi/overall/speakTest',
    method: 'post',
    data
  })
}
//
// // 获取老师下的班级
// export function getClassList (data) {
//   return request({
//     url: '/openApi/overall/getClassList',
//     method: 'post',
//     data
//   })
// }

export function loginAuth (params) {
  return request({
    url: `${process.env.VUE_APP_RESOURCE_URL}/login`,
    method: 'post',
    params,
    data: {}
  })
}

// 获取语音识别ws接口地址，与appid
export function websocketInfo (data) {
  return request({
    url: '/openApi/overall/speech/websocketInfo',
    method: 'post',
    data
  })
}

export function onlineLessonExcel (data) {
  return request({
    url: '/openApi/overall/excelHelper/onlineLesson',
    method: 'post',
    data
  })
}

export function etBase64ByUrl (data) {
  return request({
    url: '/openApi/else/voiceInteraction/getBase64ByUrl',
    method: 'post',
    data
  })
}

export function answerAnalyse (data) {
  return request({
    url: '/openApi/overall/answerAnalyse',
    method: 'post',
    data
  })
}

// 日语口语评测demo
export function speakTestJapDemo (data) {
  return request({
    url: '/openApi/overall/speakTestJapDemo',
    method: 'post',
    data
  })
}

export function uploadShangHai (data) {
  return request({
    url: '/openApi/overall/uploadShangHai',
    method: 'post',
    data
  })
}

export function addFace (data) {
  return request({
    url: '/openApi/facialRecognition/addFace',
    method: 'post',
    data
  })
}
export function getTeacherName (data) {
  return request({
    url: '/openApi/teacher/index/main',
    method: 'post',
    data
  })
}
export function getStudentName (data) {
  return request({
    url: '/openApi/student/index/main',
    method: 'post',
    data
  })
}
export function subjectCollectAnswerAnalyse (data) {
  return request({
    url: '/student/training/subjectCollectAnswerAnalyse',
    method: 'post',
    data
  })
}


// 获取学校列表
export function getSchoolList (data) {
  return request({
    url: '/system/getSchoolList',
    method: 'get',
    params: data
  })
}

// 获取学校下的老师列表
export function getTeacherList (data) {
  return request({
    url: '/system/getTeacherList',
    method: 'get',
    params: data
  })
}

// 获取班级下的班级列表
export function getClassList (data) {
  return request({
    url: '/system/getClassList',
    method: 'get',
    params: data
  })
}

// 更新学校班级教师
export function updateHeaderData (data) {
  return request({
    url: '/system/updateHeaderData',
    method: 'post',
    data
  })
}

// 11.获取班级id（用户是否有班级）（用户拥有的班级列表取最新创建的）
export function getNowClassId (data) {
  return zyrequest({
    url: '/openApi/overall/getNowClassId',
    method: 'post',
    data
  })
}

// 获取学校下的老师列表
export function getTeacherDynList (data) {
  return request({
    url: '/system/getTeacherDynList',
    method: 'get',
    params: data
  })
}

// 获取学校下的老师列表
export function getTeacherXnList (data) {
  return request({
    url: '/system/getTeacherXnList',
    method: 'get',
    params: data
  })
}

export function updateDefaultSelect (data) {
  return zyrequest({
    url: '/openApi/overall/updateDefaultSelect',
    method: 'post',
    data
  })
}

// 全局--班级课程选择
export function getIntVrClassResource (data) {
  return zyrequest({
    url: '/openApi/overall/getIntVrClassResource',
    method: 'post',
    data
  })
}

export function baiduTranslate (data) {
  return zyrequest({
    url: '/openApi/overall/speech/baiduTranslate',
    method: 'post',
    data
  })
}
// 班级选择（动态考试测评）
export function getDynExamClassResource () {
  return zyrequest({
    url: '/openApi/overall/getDynExamClassResource',
    method: 'post'
  })
}
