/**
 * create by hp
 * date 2020/2/28 9:56
 * @type {string[]}
 */
import Vue from 'vue'

const noData = function (el, data) {
  const flagStr = 'hp-xxxxxxx-xxxxxx-xxxxx'
  const tempUsrHtml = data.value ? `<div data-flag="${flagStr}" style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;">${data.value}</div>` : null
  const innerHTML = tempUsrHtml || `<div data-flag="${flagStr}" style="font-size: 14px;width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;">暂无数据</div>`

  if (el.hasChildNodes()) {
    for (let node of el.childNodes) {
      const flag = node.dataset.flag
      if (flag && flag === flagStr) {
        el.removeChild(node)
      }
    }
  }
  if (el.hasChildNodes()) return
  el.innerHTML = innerHTML
}

Vue.directive('noData', {
  componentUpdated: noData,
  bind: noData
})
