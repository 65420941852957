const getters = {
  sidebar: state => state.app.sidebar,
  visitedViews: state => state.app.visitedViews,

  realname: state => state.user.userInfo.realname,
  userId: state => state.user.userInfo.userId,
  avatar: state => 'https://www.gravatar.com/avatar/6560ed55e62396e40b34aac1e5041028',
  role: state => state.user.userInfo.roleName,
  menus: state => state.user.userInfo.menuList,
  permissions: state => state.user.userInfo.permissionList,
  isCompetition: state => false,
  permission_routers: state => state.permission.routers,
  addRouters: state => state.permission.addRouters,
  encryptedAccount: state => state.user.encryptedAccount,

  userInfo: state => state.user.userInfo,
  systemBase: state => state.loadingBase.systemBase,
  user: state => state.user,
  manageRoute: state => state.manageRoute,
  schoolInfo: state => state.user.schoolInfo,
  loginInfo: state => state.user.schoolInfo,
  isRecord: state => state.user.isRecord,
  isEval: state => state.evaluate.isEval,
  menuIndex: state => state.evaluate.menuIndex,
  isVr: state => state.vr.isVr
}
export default getters
