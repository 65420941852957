<template>
  <div class="back-ground" @mouseup.stop="mouseupFu">
    <div class="translate-box456465" v-if="showTranslate" :style="{'opacity': opacity}" @click="showTranslate = false" >
      <div ref="translate-box456465">
        <baidu-translate :contentText.sync="contentText" :opacity.sync="opacity" style="position: absolute;" :style="{'left': l + 'px', 'top': t + 'px'}"></baidu-translate>
      </div>
    </div>
    <div v-for="(item, index) of titleList" :key="index" class="item-box">
      <!-- 文本 -->
      <div v-if="Number(item.displayType) === 1" class="txt-box" >
        <span v-if="item.htmlStr" v-html="item.htmlStr"></span>
        <span v-else>{{item.resourceTrainingContentEn || item.caseContent}}</span>
      </div>
      <!-- 图片 -->
      <viewer v-else-if="Number(item.displayType) === 2">
        <img :src="item.fileUrl" class="img-box"/>
      </viewer>
      <!-- 音频 -->
      <audio-slot v-else-if="Number(item.displayType) === 3">
        <audio ref="audio" @ended="setFirst" controls :src="item.fileUrl" controlsList="nodownload" @contextmenu.prevent="" class="audio-box"></audio>
      </audio-slot>
      <!-- 视频 -->
<!--      <video-player @ended="setFirst" v-else-if="Number(item.displayType) === 4" class="video-player vjs-custom-skin" v-once :options="$videoConf(item.fileUrl)" :playsinline="true"></video-player>-->
      <tack-video  @ended="setFirst"
                   :file-url="item.fileUrl"
                   v-else-if="Number(item.displayType) === 4"
                   :vtt-url="item.subtitlesEnUrl"
                   :subtitles-is-opened="item.subtitlesIsOpened "/>
    </div>
  </div>
</template>

<script>
import baiduTranslateMixin from '@/components/baiduTranslate/baiduTranslateMixin'
export default {
  name: 'backGround',
  mixins: [baiduTranslateMixin],
  props: {
    titleList: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    setFirst () {
      this.$emit('setFirst')
    }
  },
  created () {
    this.$stopPlay()
  },
  beforeDestroy () {
    this.$stopPlay()
  }
}
</script>

<style scoped lang="scss">
  .back-ground {
    width: 100%;
    height: 100%;
    .item-box {
      width: 100%;
      margin-bottom: 10px;
      .txt-box {
        width: 100%;
        font-size: 14px;
        line-height: 20px;
        white-space: pre-wrap;
        word-break: keep-all !important; // 单词不能从中间换行
        text-align: justify;
        user-select: text;
      }
      .img-box {
        width: 100%;
        height: auto;
      }
      .audio-box{
        width: 100%;
        height: 50px;
      }
    }
  }
</style>
