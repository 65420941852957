<template>
  <div ref="audioBox" @click.stop="" class="hp-audio-box">
    <slot></slot>
    <el-dropdown @command="command">
      <span class="cursor right-control">{{speedInner}}x</span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item :command="item" v-for="(item, index) of speedList" :key="index">{{item}}x</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  props: {
    speedList: {
      type: Array,
      default: () => [1.5, 1, 0.7]
    },
    speed: {
      type: Number,
      default: () => 1
    }
  },
  watch: {
    speed (val) {
      this.speedInner = this.speed
    },
    speedInner (val) {
      this.playbackRate(val)
    }
  },
  data () {
    return {
      speedInner: 1,
      audioDom: null
    }
  },
  methods: {
    command (info) {
      this.speedInner = info
    },
    playbackRate (val) {
      if (!this.audioDom) return
      this.audioDom.playbackRate = Number(val)
    }
  },
  async mounted () {
    await this.$nextTick()
    const audioDom = this.$refs['audioBox'].children[0]
    audioDom.style.width = '100%'
    if (audioDom.tagName.toUpperCase() !== 'AUDIO') throw Error('audio slot is not a <aduio> tag')
    this.audioDom = audioDom
  }
}
</script>

<style scoped lang="scss">
.hp-audio-box {
  width: 100%;
  display: flex;
  align-items: center;
  background: #f5f5f5;
  border-radius: 10px;
  position: relative;
  .right-control {
    width: 30px;
    display: block;
    height: 100%;
    font-size: 14px;
  }
}
</style>
