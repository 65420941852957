<template>
  <div ref="drag" class="zy-main-drag" @mousedown="handleMousedown" :style="{'visibility': (left === 1 || left === 0) ? 'hidden' : ''}"></div>
</template>

<script>
export default {
  name: 'ZyMainDrag',
  data () {
    return {
      spTriggerWidth: 8,
      spMin: 0.05, // 左边/上边最小 大小
      spMax: 0.98 // 右边/下边最大 大小
    }
  },
  props: {
    // 百分位
    left: {
      type: Number,
      default: () => 0.5
    }
  },
  methods: {
    handleMousedown (event) {
      document.addEventListener('mousemove', this.handleMousemove)
      document.addEventListener('mouseup', this.handleMouseup)
      document.body.style['user-select'] = 'none'
    },
    handleMousemove (event) {
      const parentDom = this.$refs.drag.parentNode.getBoundingClientRect()
      let offsetPercent = (event.pageX + this.spTriggerWidth - parentDom.left) / parentDom.width
      if (offsetPercent < this.spMin) offsetPercent = this.spMin
      if (offsetPercent > this.spMax) offsetPercent = this.spMax
      this.setDom(offsetPercent)
    },
    handleMouseup () {
      document.removeEventListener('mousemove', this.handleMousemove)
      document.removeEventListener('mouseup', this.handleMouseup)
      document.body.style['user-select'] = 'auto'
    },
    /**
     * 获取相邻元素
     * @param ele 参考物元素
     * @param type 类型，上一个(1)or下一个(0)
     * @return 返回查找到的元素Dom对象，无则返回null
     */
    getNearEle (ele, type) {
      type = type === 1 ? 'previousSibling' : 'nextSibling'
      let nearEle = ele[type]
      while (nearEle) {
        if (nearEle.nodeType === 1) {
          return nearEle
        }
        nearEle = nearEle[type]
        if (!nearEle) {
          break
        }
      }
      return null
    },
    setDom (val) {
      // 拖动块元素
      let dragDom = this.$refs.drag
      dragDom.style.left = `calc(${val * 100}% - 9px)`
      // 设置相邻左边的元素宽度
      let leftDom = this.getNearEle(this.$refs.drag, 1)
      leftDom.style.width = `calc(${val * 100}% - 10px)`
      // 设置相邻右边的元素宽度
      let rightDom = this.getNearEle(this.$refs.drag, 0)
      rightDom.style.width = `calc(100% - ${val * 100}%)`
    }
  },
  mounted () {
    this.setDom(this.left)
  }
}
</script>

<style scoped>

</style>
