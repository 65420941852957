const state = {
  isVr: sessionStorage.getItem('isVr') ? JSON.parse(sessionStorage.getItem('isVr')) : false,
  menuIndex: sessionStorage.getItem('menuIndex') ? JSON.parse(sessionStorage.getItem('menuIndex')) : {}
}

const mutations = {
  SET_IS_VR: (state, data) => {
    state.isVr = data
  },
  SET_MENU_INDEX: (state, data) => {
    state.menuIndex = data
  }
}

const actions = {
  // 获取设置Vr状态
  async setIsVr ({ commit }, data) {
    sessionStorage.setItem('isVr', JSON.stringify(data))
    commit('SET_IS_VR', data)
  },
  // 菜单列表下标
  async setMenuIndex ({ commit }, data) {
    sessionStorage.setItem('menuIndex', JSON.stringify(data))
    commit('SET_MENU_INDEX', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
