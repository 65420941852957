export function timestampToTime (timestamp) {
  let date = new Date(timestamp)
  let Y = date.getFullYear() + '-'
  let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
  let D = date.getDate() + ' '
  let h = date.getHours() + ':'
  let m = date.getMinutes() + ':'
  let s = date.getSeconds()
  return Y + M + D + h + m + s
}
export function timestampToDate (timestamp) {
  let date = new Date(timestamp)
  let Y = date.getFullYear() + '-'
  let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
  let D = date.getDate() + ' '
  return Y + M + D
}

/**
 *获取下一个月的输入日期
 *{param:DateTime} date 输入日期(YYYY-MM-DD)
 *{param:number } monthNum 月数
 */
export function GetNextMonthDay (date, monthNum) {
  var dateArr = date.split('-')
  var year = dateArr[0] // 获取当前日期的年份
  var month = dateArr[1] // 获取当前日期的月份
  var day = dateArr[2] // 获取当前日期的日
  // var days = new Date(year, month, 0)
  // days = days.getDate() // 获取当前日期中的月的天数
  var year2 = year
  var month2 = parseInt(month) + parseInt(monthNum)
  if (month2 > 12) {
    year2 = parseInt(year2) + parseInt((parseInt(month2) / 12 === 0 ? 1 : parseInt(month2) / 12))
    month2 = parseInt(month2) % 12
  }
  var day2 = day
  var days2 = new Date(year2, month2, 0)
  days2 = days2.getDate()
  if (day2 > days2) {
    day2 = days2
  }
  if (month2 < 10) {
    if (month2 == 0) {
      month2 = '01'
    } else {
      month2 = '0' + month2
    }
  }
  var t2 = year2 + '-' + month2 + '-' + day2
  return t2
}

// 秒格式化
export function secondFormat (value) {
  var secondTime = parseInt(value) // 秒
  var minuteTime = 0 // 分
  var hourTime = 0 // 小时
  if (secondTime > 60) { // 如果秒数大于60，将秒数转换成整数
    // 获取分钟，除以60取整数，得到整数分钟
    minuteTime = parseInt(secondTime / 60)
    // 获取秒数，秒数取佘，得到整数秒数
    secondTime = parseInt(secondTime % 60)
    // 如果分钟大于60，将分钟转换成小时
    if (minuteTime > 60) {
      // 获取小时，获取分钟除以60，得到整数小时
      hourTime = parseInt(minuteTime / 60)
      // 获取小时后取佘的分，获取分钟除以60取佘的分
      minuteTime = parseInt(minuteTime % 60)
    }
  }
  var time = (secondTime < 10 ? '0' : '') + secondTime

  if (minuteTime > 0) {
    time = (minuteTime < 10 ? '0' : '') + minuteTime + ':' + time
  } else {
    time = '00:' + time
  }
  if (hourTime > 0) {
    time = hourTime + ':' + time
  } else {
    time = '0:' + time
  }
  return time
}
