import { getTeacherId, getClassId } from '@/utils/auth'

export function hasTeacherId () {
  if (getTeacherId() !== "" && getTeacherId() !== null && getTeacherId() !== '0' && getTeacherId() !== undefined) {
    return true
  } else {
    return false
  }
}

export function hasTeacherIdAndClassId () {
  if (getTeacherId() !== "" && getTeacherId() !== null && getTeacherId() !== '0' && getTeacherId() !== undefined &&
    getClassId() !== "" && getClassId() !== null && getClassId() !== '0' && getClassId() !== undefined) {
    return true
  } else {
    return false
  }
}
