import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import {
  getToken,
  getClassId,
  getResourceLessonId,
  removeToken,
  getExamTypeId,
  getExamClassId,
  getVrLessonId,
  getIntVrClassId,
  // getLessonVersionId,
  getZyzhTeacherToken,
  getOperateUserId
} from '@/utils/auth'




axios.defaults.baseURL = process.env.VUE_APP_BASE_API2
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API2, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    if (getZyzhTeacherToken()) {
      config.headers['token'] = getZyzhTeacherToken() // token
      config.headers['classId'] = getClassId() // 班级ID
      config.headers['resourceLessonId'] = getResourceLessonId() // 课程ID
      config.headers['from-operate'] = '1' // 是否为运营平台,1为是
      config.headers['operate-user-id'] = getOperateUserId() // 运营平台用户id,1为是
      config.headers['exam-class-id'] = getExamClassId() // 动态测评班级ID
      config.headers['exam-type-id'] = getExamTypeId() // 动态测评试卷类型ID
      config.headers['vr-lesson-id'] = getVrLessonId() // 虚拟仿真ID
      config.headers['int-vr-class-id'] = getIntVrClassId() // 虚拟仿真ID
      // config.headers['lesson-version-id'] = getLessonVersionId() // 课程ID
    }
    config.headers['app-type'] = 3 // 客户端类型 3：web
    config.headers['app-version'] = process.env.VUE_APP_Version // 版本号
    console.log(config);
    return config
  },
  error => {
    // do something with request error
    window.console.error(error) // for debug
    return Promise.reject(error)
  }
)

var offLine = false
// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    // 帐号被禁用
    if (Number(response.data.code) === 521) {
      if (!offLine) {
        offLine = true
        MessageBox.alert(response.data.message, '温馨提示', {
          confirmButtonText: '确定',
          center: 'center',
          showClose: false,
          callback: action => {
            removeToken()
            location.reload()
            offLine = false
          }
        })
      }
      return
    }
    if (Number(response.data.code) === 700) {
      removeToken()
      location.reload()
      return
    }
    return response.data
  },
  error => {
    Message({
      message: error.response.data
        ? error.response.data.message
        : error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
