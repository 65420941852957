<template>
<div class="header">
  <div class="logoName">
    中语智汇运营平台
    <span class="dropdown-list">学校：</span>
    <el-select style="width: 320px" v-model="schoolId" filterable @change="changeSchool">
      <el-option
        v-for="(item,index) in schoolList"
        :key="`school_${index}`"
        :label="item.schoolName"
        :value="item.schoolId"
      ></el-option>
    </el-select>
    <span class="dropdown-list" v-if="!isEval && !isVr">教师：</span>
    <el-select style="width: 160px" v-model="teaId" filterable @change="changeTeacher" v-if="!isEval && !isVr">
      <el-option
        v-for="(item,index) in teacherList"
        :key="`tea_${index}`"
        :label="item.teacherName"
        :value="item.teaId"
      ></el-option>
    </el-select>
    <span class="dropdown-list" v-if="isEval">动态测评教师：</span>
    <el-select style="width: 160px" v-model="teaDynId" filterable @change="changeDynTeacher" v-if="isEval">
      <el-option
        v-for="(item,index) in teacherDynList"
        :key="`tea_${index}`"
        :label="item.teacherName"
        :value="item.teaId"
      ></el-option>
    </el-select>
    <span class="dropdown-list" v-if="isVr">虚拟仿真实验教师：</span>
    <el-select style="width: 160px" v-model="teaXnId" filterable @change="changeXnTeacher" v-if="isVr">
      <el-option
        v-for="(item,index) in teacherXnList"
        :key="`tea_${index}`"
        :label="item.teacherName"
        :value="item.teaId"
      ></el-option>
    </el-select>
    <span class="dropdown-list" v-if="!isEval && !isVr">班级-课程：</span>
    <el-select style="width: 320px" v-model="classId" filterable @change="changeClass" v-if="!isEval && !isVr">
      <el-option
        v-for="(item,index) in classList"
        :key="`class_${index}`"
        :label="item.className + '-' + item.resourceLessonName"
        :value="item.classId"
      ></el-option>
    </el-select>
  </div>
  <!--<el-button @click="dynamicFu">动态</el-button>-->
  <div class="headerRight">
    <template v-if="!isVr&&!isEval">
      <div class="inlineBlock item" @click="vrFu" style="width: 38px">
        <img src="~@/img/icon_xn.png" style="padding-top: 10px; cursor: pointer;" title="虚拟仿真实验">
      </div>
    </template>
    <template v-if="!isVr&&!isEval">
      <div class="inlineBlock item" @click="dynamicFu" style="width: 38px">
        <img src="~@/img/icon_sj.png" style="padding-top: 10px; cursor: pointer;" title="动态考试测评">
      </div>
    </template>
    <router-link class="inlineBlock item" @click.native="backTo" to="/" style="width: 38px">
      <img src="~@/img/icon_sy.png" style="padding-top: 10px; cursor: pointer;" title="首页">
    </router-link>
    <el-dropdown v-if="false" trigger="click" class="item" @command="handleCommand">
      <span class="el-dropdown-link">
        个人中心<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
    </el-dropdown>
    <el-dropdown class="avatar-container item" trigger="click">
      <div class="avatar-wrapper">
        <img class="user-avatar" v-if="user.headImgUrl" :src="user.headImgUrl">
        <img class="user-avatar" v-else src="~@/img/user1.png">
        <i class="el-icon-arrow-down el-icon--right"></i>
      </div>
      <el-dropdown-menu class="user-dropdown" slot="dropdown">
        <el-dropdown-item >
          <el-button type="text" @click="logout" >退出</el-button>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import {getSchoolId, getTeacherId, getClassId, setResourceLessonId, setClassId, setSchoolId, setTeacherId, removeToken,  getZyzhTeacherToken
, setZyzhTeacherToken, removeZyzhTeacherToken, setExamClassId, setVrLessonId, setIntVrClassId } from '@/utils/auth'
import { getSchoolList, getTeacherList, getClassList, updateHeaderData, getNowClassId, getTeacherDynList, getTeacherXnList } from '@/api/overall'
import { checkTeaDynExam } from '@/api/teacher/dynExam/main'
import { hasTeacherId } from '@/utils/judge'
import store from "@/store";
export default {
  components: {
  },
  computed: {
    ...mapGetters([
      'user',
      'schoolInfo',
      'isEval',
      'isVr'
    ])
  },
  data() {
    return {
      schoolName: '请选择学校',
      teacherName:'请选择老师',
      className: '请选择班级',
      schoolId: '',
      teaId: '',
      teaDynId: '',
      teaXnId: '',
      classId: '',
      schoolList: [],
      teacherList: [],
      teacherDynList: [],
      teacherXnList: [],
      classList: [],
      xnClassList: [],
      resourceLessonType: '0'
    }
  },
  created() {
    this.getSchoolList();
  },
  watch: {
    isEval: function (val) {
      this.getSchoolList();
    },
    isVr: function (val) {
      this.getSchoolList();
    }
  },
  methods: {
    ...mapActions('user', ['setSchoolInfo']),
    ...mapActions('evaluate', ['setIsEval']),
    ...mapActions('vr', ['setIsVr']),
    // 获取学校列表
    async getSchoolList() {
      const data = await getSchoolList({})
      this.schoolList = data.list
      this.schoolId = getSchoolId()
      this.schoolList.forEach(item => {
        if (this.schoolId === item.schoolId) {
          this.schoolName = item.schoolName
        }
      })
      if(this.isEval){
        this.getTeacherDynList(this.schoolId, "2")
      }else if(this.isVr){
        this.getTeacherXnList(this.schoolId, "2")
      }else{
        this.getTeacherList(this.schoolId)
      }
    },
    // 获取老师列表
    async getTeacherXnList(schoolId, type) {
      var param = false;
      if(schoolId){
        const data = await getTeacherXnList({
          schoolId: schoolId
        })
        this.teacherXnList = data.list
        if(type !== "2"){ //1从改变学校过来,2为初始化时过来
          this.teaXnId = ''
        }else{
          this.teaXnId = getTeacherId()
        }
        this.teacherXnList.forEach(item => {
          if (this.teaXnId === item.teaId) {
            this.teacherName = item.teacherName
            param = true;
          }
        })
        if(!param){
          this.teaXnId = ''
          if(this.teacherXnList.length > 0){
            this.teaXnId = this.teacherXnList[0].teaId
          }
          this.changeTeacher(this.teaXnId);
        }else{
          const data2 = this.$checkApiServer(await getNowClassId({ classType: 2 }))
          setVrLessonId(data2.vrLessonId)
          setIntVrClassId(data2.classId)
          this.setSchoolInfo(Object.assign(this.schoolInfo, {
            vrLessonId: data2.vrLessonId,
            intVrClassId: data2.classId
          }))
        }
//        this.getClassList(schoolId, this.teaId)
      }
    },
    // 获取老师列表
    async getTeacherDynList(schoolId, type) {
      var param = false;
      if(schoolId){
        const data = await getTeacherDynList({
          schoolId: schoolId
        })
        this.teacherDynList = data.list
        if(type !== "2"){ //1从改变学校过来,2为初始化时过来
          this.teaDynId = ''
        }else{
          this.teaDynId = getTeacherId()
        }
        this.teacherDynList.forEach(item => {
          if (this.teaDynId === item.teaId) {
            this.teacherName = item.teacherName
            param = true;
          }
        })
        if(!param){
          this.teaDynId = ''
          if(this.teacherDynList.length > 0){
            this.teaDynId = this.teacherDynList[0].teaId
           }
          this.changeTeacher(this.teaDynId);
        }else{
          const data2 = this.$checkApiServer(await getNowClassId({ classType: 1 }))
          setExamClassId(data2.classId)
          this.setSchoolInfo(Object.assign(this.schoolInfo, {
            examClassId: data2.classId
          }))
        }
//        this.getClassList(schoolId, this.teaId)
      }
    },
    // 获取老师列表
    async getTeacherList(schoolId) {
      var param = false;
      if(schoolId && !this.isEval && !this.isVr){
        const data = await getTeacherList({
          schoolId: schoolId
        })
        this.teacherList = data.list
        this.classList = []
        this.classId = ''
        this.teaId = getTeacherId()
        this.teacherList.forEach(item => {
          if (this.teaId === item.teaId) {
            this.teacherName = item.teacherName
            param = true;
          }
        })

        if(!param){
          this.teaId='';
          if(this.teacherList.length>0){
            this.teaId = this.teacherList[0].teaId
          }
          this.teacherList.forEach(item => {
            if (this.teaId === item.teaId) {
              this.teacherName = item.teacherName
              param = true;
            }
          })
          this.changeTeacher(this.teaId)
        }
        this.getClassList(schoolId, this.teaId)
      }
    },
    // 获取班级列表
    async getClassList(schoolId, teaId) {
      if(schoolId && teaId){
        const data = await getClassList({
          schoolId: schoolId,
          teaId: teaId
        })
        this.classList = data.list
        this.classId = getClassId()
        if((getClassId() == "" || getClassId() == null)  && this.classList.length > 0){
          this.classId = this.classList[0].classId
        }
        this.classList.forEach(item => {
          if (this.classId === item.classId) {
            this.className = item.className + '-' + item.resourceLessonName
            this.resourceLessonType = item.resourceLessonType
          }
        })
      }else{
        this.classList = []
      }
    },
    // 更改学校
    async changeSchool(schoolId) {
      this.schoolId = schoolId
      this.schoolList.forEach(item => {
        if (this.schoolId === item.schoolId) {
          this.schoolName = item.schoolName
        }
      })
      await this.updateHeadData({
        schoolId: this.schoolId,
        type:"1"
      })
      this.teacherList = []
      this.classList = []
      this.teacherDynList = []
      if(this.isEval){
        this.getTeacherDynList(this.schoolId,"1")
      }else if(this.isVr){
        this.getTeacherXnList(this.schoolId,"1")
      }else{
        this.getTeacherList(this.schoolId)
      }
    },
    // 更改老师
    async changeTeacher(teaId) {
      this.teaId = teaId
      this.teacherList.forEach(item => {
        if (this.teaId === item.teaId) {
          this.teacherName = item.teacherName
        }
      })
      await this.updateHeadData({
        schoolId: getSchoolId(),
        teaId: this.teaId,
        type: "2"
      })
      this.classList = []
      this.getClassList(getSchoolId(), this.teaId)
    },
    // 更改老师
    async changeDynTeacher(teaId) {
      this.teaDynId = teaId
      this.teacherDynList.forEach(item => {
        if (this.teaDynId === item.teaId) {
          this.teacherName = item.teacherName
        }
      })
      await this.updateHeadData({
        schoolId: getSchoolId(),
        teaId: this.teaDynId,
        type: "2",
      })
      this.classList = []
//      this.getClassList(getSchoolId(), this.teaId)
    },
    // 更改老师
    async changeXnTeacher(teaId) {
      this.teaXnId = teaId
      this.teacherXnList.forEach(item => {
        if (this.teaXnId === item.teaId) {
          this.teacherName = item.teacherName
        }
      })
      await this.updateHeadData({
        schoolId: getSchoolId(),
        teaId: this.teaXnId,
        type: "2",
      })
      this.classList = []
//      this.getClassList(getSchoolId(), this.teaId)
    },
    // 更改班级
    changeClass(classId) {
      this.classId = classId
      this.classList.forEach(item => {
        if (this.classId === item.classId) {
          this.className = item.className + '-' + item.resourceLessonName
          this.resourceLessonType = item.resourceLessonType
        }
      })
      this.updateHeadData({
        schoolId: getSchoolId(),
        teaId: getTeacherId(),
        classId: this.classId,
        type: "3"
      })
    },
    logout() {
      removeToken()
      sessionStorage.clear()
      localStorage.clear()
      store.dispatch('user/outLogin')
      this.$router.push({name: 'login'})
    },
    handleCommand(command) {
      this.$router.push({ path: command })
    },
    async updateHeadData(obj) {
      if(this.isEval){
        Object.assign(obj, {
          isEval: "1"
        })
      }
      if(this.isVr){
        Object.assign(obj, {
          isEval: "2"
        })
      }
      const data = await updateHeaderData(obj)
      setResourceLessonId(data.resourceLessonId)
      setClassId(data.classId)
      setSchoolId(data.schoolId)
      setTeacherId(data.teacherId)
      setZyzhTeacherToken(data.zyzhTeacherToken)
      this.setSchoolInfo({
        examClassId: null,
        vrLessonId: null,
        intVrClassId: null,
        schoolId: data.schoolId,
        teacherId: data.teacherId,
        resourceLessonId: '',
        resourceLessonType: '',
        classId: '',
        className: ''
      })
      if(!this.isEval && !this.isVr){
        this.setSchoolInfo(Object.assign(this.schoolInfo, {
          resourceLessonId: data.resourceLessonId,
          resourceLessonType: data.resourceLessonType,
          classId: data.classId,
          className: this.className.split('-')[0],
        }))
      }
      if(hasTeacherId() && this.isEval){
//        alert("0")
        const data2 = this.$checkApiServer(await getNowClassId({ classType: 1 }))
        setExamClassId(data2.classId)
        this.setSchoolInfo(Object.assign(this.schoolInfo, {
          examClassId: data2.classId
        }))
      }
      if(hasTeacherId() && this.isVr){
        const data2 = this.$checkApiServer(await getNowClassId({ classType: 2 }))
        this.setSchoolInfo(Object.assign(this.schoolInfo, {
          vrLessonId: data2.vrLessonId,
          intVrClassId: data2.classId
        }))
        setVrLessonId(data2.vrLessonId)
        setIntVrClassId(data2.classId)

      }
    },
    // 动态考试测评
    async dynamicFu () {
//      const data2 = this.$checkApiServer(await getNowClassId({ classType: 1 }))
//      setExamClassId(data2.classId)
//      this.setSchoolInfo(Object.assign(this.schoolInfo, {
//        examClassId: data2.classId
//      }))
      sessionStorage.removeItem('Sidebar:menuIndex')
      this.$router.push("/dynamic/dynamicExamManage/evaluationDym")
      this.setIsEval(true)
      this.setIsVr(false)
    },
    backTo(){
      this.setIsEval(false)
      this.setIsVr(false)
    },
    // 虚拟仿真
    async vrFu () {
      sessionStorage.removeItem('Sidebar:menuIndex')
      this.$router.push("/simulation/main")
      this.setIsVr(true)
      this.setIsEval(false)
    },
}
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>

 .header{
    width: 100%;
    height: 60px;
    line-height: 60px;
    overflow: hidden;
    background: #1f2d3d;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1001;

    .logoName{
      float: left;
      padding-left: 84px;
      font-size: 22px;
      color: #ffffff;
      background: url(./img/logo-1.png) 42px center no-repeat;
      background-size: 30px;
      .dropdown-list {
        margin-left: 20px;
        .el-dropdown-link {
          color: #009efd;
          font-size: 16px;
          font-weight: 600;
          .el-dropdown-lable {
            color: #ffffff;
            font-size: 16px;
          }
        }
      }
    }
    .headerRight{
      float: right;
      margin-right: 5px;

      .item{
        float: left;
        margin-left: 5px;
        color: #ffffff;
        font-size: 14px;
        cursor: pointer;
      }
    }
   .dropdown-list {
     margin-left: 20px;
     color: #009efd;
     font-size: 16px;
     font-weight: 600;
     color: #ffffff;
   }
   ::v-deep .el-input__inner{
     background-color: #1f2d3d !important;
     border-bottom: 0.1px dashed !important;
     border-top: 1px saddlebrown !important;
     border-left: 1px saddlebrown !important;
     border-right: 1px saddlebrown !important;
     color: #409EFF !important;
     font-size: 14px !important;
   }
}
.avatar-container {
    height: 50px;
    display: inline-block;
    .avatar-wrapper {
      cursor: pointer;
    //   margin-top: 5px;
      position: relative;
      .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        vertical-align: middle;
      }
      .el-icon-caret-bottom {
        position: absolute;
        // right: -20px;
        // top: 25px;
        font-size: 12px;
      }
    }
  }

</style>
