const state = {
  isEval: sessionStorage.getItem('isEval') ? JSON.parse(sessionStorage.getItem('isEval')) : false,
  menuIndex: sessionStorage.getItem('menuIndex') ? JSON.parse(sessionStorage.getItem('menuIndex')) : {}
}

const mutations = {
  SET_IS_Eval: (state, data) => {
    state.isEval = data
  },
  SET_MENU_INDEX: (state, data) => {
    state.menuIndex = data
  }
}

const actions = {
  // 获取设置测评状态
  async setIsEval ({ commit }, data) {
    sessionStorage.setItem('isEval', JSON.stringify(data))
    commit('SET_IS_Eval', data)
  },
  // 菜单列表下标
  async setMenuIndex ({ commit }, data) {
    sessionStorage.setItem('menuIndex', JSON.stringify(data))
    commit('SET_MENU_INDEX', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
